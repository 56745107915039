<template>
    <re-page max-width="xl" page-title="Admin dashboard" main-content-class="q-pa-md">
        <!-- per-page select -->
        <div class="re-mb row justify-start items-start">
            <re-select
                v-model="perPage"
                size="sm"
                :options="
                    perPageList.map((item) => ({
                        label: `${item} ${$t('monolith.shared.per_page')}`,
                        value: item,
                    }))
                "
                map-options
                emit-value
            />
        </div>
        <!-- input section -->
        <q-table
            v-model:pagination="pagination"
            :rows="data"
            row-key="id"
            :columns="columns"
            :loading="isLoading"
            :per-page="perPage"
            :filter-method="filterRequests"
            :filter="filters"
        >
            <template #header="slotProps">
                <q-tr :props="slotProps">
                    <q-th v-for="col in slotProps.cols" :key="col.name" :props="slotProps" class="header">
                        {{ col.label }}
                    </q-th>
                </q-tr>
                <tr :props="slotProps" class="filter-header">
                    <th v-for="col in slotProps.cols" :key="col.name" :props="slotProps">
                        <re-input
                            v-if="col.searchable !== false"
                            v-model.trim="colFilters[col.name]"
                            size="sm"
                            @click.stop
                        >
                            <template #prepend>
                                <re-icon name="mdi-magnify" size="xs" />
                            </template>
                        </re-input>
                    </th>
                </tr>
            </template>
            <template #body-cell-editable="props">
                <q-td :props="props">
                    <div class="q-gutter-sm">
                        <re-button
                            v-if="!props.row.editable && props.row.new_user"
                            no-min-width
                            icon="mdi-plus"
                            color="main"
                            size="sm"
                            :label="$t('common.new')"
                            @click="toggle_editing(props, true)"
                        />
                        <template v-else-if="props.row.editable">
                            <re-button
                                v-if="!props.row.deleting"
                                no-min-width
                                icon="mdi-content-save"
                                type="is-success"
                                size="sm"
                                @click="toggle_editing(props, true)"
                            />
                            <re-button
                                no-min-width
                                icon="mdi-close"
                                type="sm"
                                size="sm"
                                @click="toggle_editing(props, false)"
                            />
                        </template>
                        <template v-else-if="props.row.deleting">
                            <re-button
                                no-min-width
                                icon="mdi-delete-forever"
                                color="negative"
                                size="sm"
                                label="confirm deletion"
                                @click="delete_row(props)"
                            />
                            <re-button
                                v-if="props.row.editable || props.row.deleting"
                                no-min-width
                                icon="mdi-close"
                                size="sm"
                                @click="toggle_editing(props, false)"
                            />
                        </template>
                        <template v-else>
                            <re-button
                                no-min-width
                                icon="mdi-pencil"
                                color="main"
                                outlined
                                size="sm"
                                @click="toggle_editing(props, false)"
                            />
                            <re-button
                                no-min-width
                                icon="mdi-delete"
                                color="negative"
                                outlined
                                size="sm"
                                @click="delete_row(props)"
                            />
                        </template>
                    </div>
                </q-td>
            </template>
            <template #body-cell-roles="aProps">
                <q-td :props="aProps">
                    <admin-roles
                        v-if="aProps.row.editable || aProps.row.roles.length"
                        :roles="aProps.row.roles"
                        :editable="aProps.row.editable"
                        :unremovable="[aProps.row.is_self ? '-:admin' : null]"
                        @select-role="(r) => (aProps.row.roles = r)"
                    />
                    <!-- make it impossible to soft lock yourself out of the admin panel -->
                </q-td>
            </template>
            <template #body-cell-has_password="props">
                <q-td :props="props">
                    <div v-if="props.row.editable">
                        <div v-if="props.row.has_password">
                            <span v-if="props.row.password" size="sm" class="mr-1">
                                {{ props.row.password }}
                            </span>
                            <re-button
                                no-min-width
                                size="sm"
                                icon="mdi-key-remove"
                                @click="
                                    () => {
                                        props.row['has_password'] = false
                                        props.row['password'] = null
                                    }
                                "
                            />
                            <re-button
                                no-min-width
                                icon="mdi-sync"
                                size="sm"
                                @click="props.row['password'] = random_string(16)"
                            />
                        </div>
                        <re-button
                            v-else
                            no-min-width
                            size="sm"
                            icon="mdi-key-plus"
                            @click="
                                () => {
                                    props.row['has_password'] = true
                                    props.row['password'] = random_string(16)
                                }
                            "
                        />
                    </div>
                    <div v-else-if="!props.row.new_user">
                        {{ props.row['has_password'] ? $t('value.yes') : $t('value.no') }}
                    </div>
                </q-td>
            </template>
            <!-- <template #body-cell-plan="props">
                <q-td :props="props">
                    <q-select
                        v-if="props.row.editable"
                        v-model="props.row.plan"
                        placeholder="Select a plan"
                        required
                    >
                        <option value="limited">Limited</option>
                        <option value="trial">Trial</option>
                        <option value="pro">Pro</option>
                        <option value="unlimited">Unlimited</option>
                    </q-select>

                    <div v-else>{{ props.value }}</div>
                </q-td>
            </template> -->
            <template #body-cell-trial_end_at="props">
                <q-td :props="props">
                    <re-date-time-picker
                        v-if="props.row.editable"
                        :model-value="props.row.trial_end_at"
                        @update:model-value="(val) => (props.row.trial_end_at = val)"
                    />
                    <div v-else>
                        {{ props.value ? date_format(props.value) : null }}
                    </div>
                </q-td>
            </template>
            <template #body-cell-accreditations="props">
                <q-td :props="props">
                    <admin-accreditations
                        v-if="doesUserHaveRole(props.row, 'ovm:valuer')"
                        :user="props.row.id"
                        :editable="props.row.editable"
                    />
                </q-td>
            </template>
            <template #body-cell="props">
                <q-td :props="props">
                    <re-input
                        v-if="props.row.editable"
                        size="sm"
                        :model-value="props.value"
                        @update:model-value="props.row[props.col.name] = $event"
                    />
                    <span v-else>
                        {{ props.value }}
                    </span>
                </q-td>
            </template>
        </q-table>
        <!-- table section -->
    </re-page>
</template>

<script>
import axios from '@/shared/plugins/axios.js'
import utils from '@/shared/plugins/utils'

import AdminRoles from './AdminRoles.vue'
import AdminAccreditations from './AdminAccreditations.vue'
import { useHead } from '@unhead/vue'

const date_formatter = new Intl.DateTimeFormat('fr-BE', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    timeZone: 'Europe/Brussels',
})

export default {
    name: 'Admin',
    components: {
        AdminRoles,
        AdminAccreditations,
    },
    inject: ['curUser'],
    // mixins: [localeMixin],
    setup() {
        useHead({
            title: 'Admin dashboard',
            titleTemplate: '%s | Rock.estate App',
        })
    },
    data() {
        return {
            data: [],
            currentPage: 1,
            perPage: 25,
            perPageList: [5, 10, 25, 50],
            isLoading: true,
            date_filters: {
                trial_end_at: null,
            },
            pagination: {
                rowsPerPage: 25,
                sortBy: 'newUser',
                descending: false,
            },
            colFilters: {},
        }
    },
    computed: {
        // return filtered text
        columns() {
            return [
                {
                    name: 'editable',
                    label: this.$t('common.edit'),
                    sortable: false,
                    searchable: false,
                },
                {
                    name: 'username',
                    label: this.$t('monolith.shared.username'),
                    sortable: true,
                    field: (row) => row.username,
                },
                {
                    name: 'email',
                    label: this.$t('monolith.shared.email_address'),
                    sortable: true,
                    field: (row) => row.email,
                },
                {
                    name: 'first_name',
                    label: this.$t('monolith.global.first_name'),
                    sortable: true,
                    field: (row) => row.first_name,
                },
                {
                    name: 'last_name',
                    label: this.$t('monolith.global.last_name'),
                    sortable: true,
                    field: (row) => row.last_name,
                },
                {
                    name: 'has_password',
                    label: this.$t('monolith.shared.password'),
                    sortable: false,
                    searchable: false,
                },
                {
                    name: 'organization',
                    label: 'Organization',
                    sortable: true,
                    field: (row) => row.organization,
                },
                // {
                //     name: 'plan',
                //     label: 'Plan',
                //     sortable: true,
                //     field: (row) => row.plan,
                // },
                // {
                //     name: 'trial_end_at',
                //     label: this.$t('trial_end_at'),
                //     sortable: true,
                //     date: true,
                //     field: (row) => row.trial_end_at,
                // },
                {
                    name: 'accreditations',
                    label: this.$t('valuer_tool.valuer.accreditations'),
                    sortable: false,
                },
                {
                    name: 'roles',
                    label: this.$t('monolith.shared.roles'),
                },
            ]
        },
        filters() {
            return {
                colFilters: this.colFilters,
            }
        },
    },
    mounted() {
        this.load_data()
    },
    methods: {
        // search with multiple query
        search(search) {
            localStorage.setItem('dashboard_search', search)
        },
        doesUserHaveRole(user, role) {
            return user?.roles.includes(role)
        },
        random_string(length) {
            return utils.random_string(length)
        },
        date_format: date_formatter.format,
        load_data() {
            let url = utils.urlJoin(this.$config.VALUATION_API_URL, ['users'])
            this.isLoading = true
            this.data = []
            // Explicitly ask for full profiles (to get roles):
            axios.get(url, { params: { details: 'full' } }).then((result) => {
                const data = [{ username: null, roles: [] }, ...result.data]
                let index = 0
                data.forEach((el, __) => {
                    try {
                        data[index] = { ...el, ...this.load_user(el, false) }
                    } catch (error) {
                        console.error({ error, el })
                    }
                    index += 1
                })
                this.data = data
                this.isLoading = false
            })
        },
        load_user(raw_user, editable) {
            return {
                ...raw_user,
                roles: utils.rolesToList(raw_user.roles),
                ...{
                    new_user: !raw_user.username,
                    editable: editable,
                    trial_end_at: raw_user.trial_end_at ? new Date(raw_user.trial_end_at) : null,
                    created_at: new Date(raw_user.created_at),
                },
            }
        },
        dump_user(user) {
            return {
                ...user,
                roles: utils.listToRoles(user.roles),
                // trial_end_at: user.trial_end_at ? new Date(user.trial_end_at) : null,
            }
        },
        toggle_editing(props, save) {
            if (props.row.deleting) {
                props.row['deleting'] = false
            } else if (props.row.editable) {
                if (save) {
                    const method = props.row.new_user ? 'POST' : 'PATCH'
                    axios({
                        url: `/user/${props.row.username}`,
                        data: this.dump_user(props.row),
                        method: method,
                    }).then((result) => {
                        let new_row = this.load_user(result.data, false)
                        Object.keys(new_row).forEach((key) => (props.row[key] = new_row[key]))
                        if (method == 'POST') {
                            this.data.unshift(this.load_user({ username: null, roles: [] }))
                        }
                    })
                } else {
                    Object.keys(props.row.previous_data).forEach(
                        (key) => (props.row[key] = props.row.previous_data[key])
                    )
                    props.row['previous_data'] = null
                }
            } else {
                // deep copy by round-trip serializing to json, except for dates
                let data_clone = JSON.parse(JSON.stringify(props.row))
                this.columns.forEach((col) => {
                    if (col.date) {
                        data_clone[col.field] = props.row[col.field] ? new Date(props.row[col.field]) : null
                    }
                })
                props.row['previous_data'] = data_clone
                props.row['editable'] = true
            }
        },
        delete_row(props) {
            if (props.row.deleting) {
                axios.delete(`/user/${props.row.username}`).then((_r) => {
                    const index = this.data.findIndex((user) => user.username === props.row.username)
                    //this.$set(props.row, 'deleted', true)
                    this.data.splice(index, 1)
                })
            } else {
                props.row['deleting'] = true
            }
        },
        filterRequests(rows, { colFilters }) {
            return rows.filter((row) => {
                return Object.entries(colFilters)
                    .map(([key, value]) => {
                        if (!value) return true
                        const rowValue = row[key]
                        if (Array.isArray(rowValue)) {
                            return rowValue.join(' ').includes(value.toLowerCase())
                        }
                        return rowValue?.toLowerCase().includes(value.toLowerCase())
                    })
                    .every(Boolean)
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.header {
    // TODO: one of the header classes should cover this:
    background-color: var(--q-secondary);
    border-right: 1px solid $border-light;
    border-color: $border-light;
    color: $base;
    text-align: center;
    font-weight: bold;
    font-size: 0.9em;
}

tr.filter-header {
    height: 30px;

    th {
        background-color: var(--q-secondary);
        border-right: 1px solid $border-light;

        padding: 4px 8px;

        :deep(.q-field__control),
        :deep(.q-field__prepend) {
            height: 30px;
        }

        :deep(.q-field__control) {
            padding: 0 6px;
        }
    }
}

.overflow-hidden {
    overflow: hidden;
}

.tag > span {
    line-height: 25px;
}

.table-wrapper {
    min-height: 600px;
    position: relative;
    overflow-y: visible;
}

.searchbox {
    min-width: 300px;
}

.clear-button {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    color: grey;

    &:hover {
        color: black;
    }
}

.table {
    & > thead > tr:nth-child(2) .th-wrap > span {
        width: 100%;
    }

    & > tbody > tr > td {
        vertical-align: middle !important;
    }

    // @TODO: #colors can remove this? Found no references
    & .row_highlighted {
        background: color($warning, -90%);

        &:hover {
            background: color($warning, -80%) !important;
        }
    }

    .valuer-select-badge {
        height: 1.2rem;
        width: 1.2rem;
        border-radius: 50%;
    }
}

.button-loading .icon {
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
</style>
