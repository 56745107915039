const ersRoutes = [
    {
        path: '/ers',
        name: 'ers',
        component: () => import('./layout.vue'),
        redirect: {
            name: 'ers.welcome',
        },
        children: [
            {
                path: 'welcome',
                name: 'ers.welcome',
                component: () => import('./components/pages/Welcome.vue'),
                //
            },
            {
                path: 'submit-summary',
                name: 'ers.submit_summary',
                component: () => import('./components/pages/Summary.vue'),
                meta: {
                    animation: 'slideInDown',
                },
            },
            {
                path: 'result',
                name: 'ers.result',
                meta: {
                    public: true,
                },
                component: () => import('./components/pages/result/Result.vue'),
            },
            {
                path: 'down',
                name: 'ers.down',
                component: () => import('./components/pages/Downtime.vue'),
            },
        ],
    },
    {
        path: '/ers/report',
        name: 'ers.report',
        component: () => import('./components/pages/result/Report.vue'),
    },
    {
        path: '/ers/questionnaire',
        name: 'ers.questionnaire',
        components: {
            default: () => import('./layout.vue'),
            navigation: () => import('./components/elements/SideSteps-v2.vue'),
        },
        redirect: {
            name: 'ers.welcome',
        },
        children: [
            {
                path: 'customer-ref',
                name: 'ers.customer_ref',
                component: () => import('./components/pages/CustomerRef.vue'),
            },
            {
                path: 'address',
                name: 'ers.address',
                component: () => import('./components/pages/building_dimensions/Address.vue'),
            },
            {
                path: 'insulation/attic',
                name: 'ers.attic',
                component: () => import('./components/pages/building_dimensions/Attic.vue'),
            },
            {
                path: 'insulation/basement',
                name: 'ers.basement',
                component: () => import('./components/pages/building_dimensions/Basement.vue'),
            },
            {
                path: 'building/dimensions',
                name: 'ers.building_dimensions',
                component: () => import('./components/pages/building_dimensions/BuildingDimensions.vue'),
            },
            {
                path: 'features/living-area',
                name: 'ers.living_area',
                component: () => import('./components/pages/building_dimensions/LivingArea.vue'),
            },
            {
                path: 'features/epc',
                name: 'ers.epc',
                component: () => import('./components/pages/house_info/EPC.vue'),
            },
            {
                path: 'features/epc-score',
                name: 'ers.epc_score',
                component: () => import('./components/pages/house_info/EpcScore.vue'),
            },
            {
                path: 'features/construction-year',
                name: 'ers.construction_year',
                component: () => import('./components/pages/house_info/ConstructionYear.vue'),
            },
            {
                path: 'insulation/roof',
                name: 'ers.roof_insulation',
                component: () => import('./components/pages/insulation/InsulationPage.vue'),
                meta: {
                    step: 3,
                    substep: 3,
                    feature: 'features.roof_attributes[0]',
                    prev: 'ers.construction_year',
                    next: 'ers.wall_insulation',
                },
            },
            {
                path: 'insulation/walls',
                name: 'ers.wall_insulation',
                component: () => import('./components/pages/insulation/InsulationPage.vue'),
                meta: {
                    step: 3,
                    substep: 4,
                    feature: 'features.wall_attributes[0]',
                    prev: 'ers.roof_insulation',
                    next: 'ers.floor_insulation',
                },
            },
            {
                path: 'insulation/floor',
                name: 'ers.floor_insulation',
                component: () => import('./components/pages/insulation/InsulationPage.vue'),
                meta: {
                    step: 3,
                    substep: 5,
                    feature: 'features.floor_attributes[0]',
                    prev: 'ers.wall_insulation',
                    next: 'ers.window_glazing',
                },
            },
            {
                path: 'insulation/windows',
                name: 'ers.window_glazing',
                component: () => import('./components/pages/insulation/WindowGlazing.vue'),
            },
            {
                path: 'insulation/heating',
                name: 'ers.heating',
                component: () => import('./components/pages/consumption/Heating.vue'),
            },
            {
                path: 'consumption/solar-panels',
                name: 'ers.solar_panels',
                component: () => import('./components/pages/consumption/SolarPanels.vue'),
            },
            {
                path: 'consumption/end-section',
                name: 'ers.end_section',
                component: () => import('./components/pages/insulation/EndingSection.vue'),
            },
            {
                path: 'energy-consumption',
                name: 'ers.energy_consumption',
                component: () => import('./components/pages/consumption/EnergyConsumption.vue'),
            },
            {
                path: 'consumption/consumers',
                name: 'ers.consumers',
                component: () => import('./components/pages/consumption/Consumers.vue'),
            },
            {
                path: 'consumption/big-consumers',
                name: 'ers.big_consumers',
                component: () => import('./components/pages/consumption/ElectricVehicles.vue'),
            },
            {
                path: 'processing',
                name: 'ers.processing',
                component: () => import('./components/pages/Submitted.vue'),
            },
        ],
    },
]

export default ersRoutes
