<template>
    <div class="login is-relative">
        <div class="form-wrapper is-absolute">
            <div class="columns is-centered">
                <div class="column is-5-fullhd is-6-desktop is-12-mobile is-9-tablet mb-6">
                    <h3 class="title is-size-2">
                        {{ $t('monolith.login.title') }}
                        <span></span>
                    </h3>
                    <div v-if="$route.query.msg !== 'email-sent'" class="has-text-centered">
                        {{ $t('monolith.sso_login.welcome') }}
                    </div>
                </div>
            </div>
            <div class="columns is-centered is-multiline is-mobile">
                <re-button
                    tag="a"
                    :href="login_url"
                    size="is-medium "
                    :label="$t('monolith.global.login')"
                    type="submit"
                    color="main"
                />
            </div>
        </div>
    </div>
</template>

<script>
import utils from '@/shared/plugins/utils'
import { useHead } from '@unhead/vue'

export default {
    name: 'LogIn',
    setup() {
        useHead({
            title: 'Log In',
            titleTemplate: '%s | Rock.estate Valuation App',
        })
    },
    data() {
        return {
            email: '',
        }
    },
    computed: {
        login_url: function () {
            let org_hint = utils.getCookie('re-org-hint')
            let redirect_query_param = this.$route.query.r ? `&r=${this.$route.query.r}` : ''
            if (org_hint === 'rockestate') {
                return this.$config.ROCKESTATE_SSO_URL + redirect_query_param
            } else {
                return this.$config.CUSTOMER_SSO_URL + redirect_query_param
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.login {
    background: url('../assets/icons/img-skyline.svg') no-repeat;
    background-position-y: bottom;
    background-position-x: center;
    height: 100%;
    min-height: 100%;

    width: 100%;
    .form-wrapper {
        left: 50%;
        top: 30%;
        width: 100%;
        transform: translate(-50%, -30%);
        padding: 0 1.5rem;
        section {
            min-width: 500px;
        }
    }
    .check-card {
        width: 450px;
        .icon-box {
            border-radius: 100%;
            background: color($positive, $alpha: -80%);
        }
    }
}
</style>
