<template>
    <re-field
        :class="['re-multi-choice', { [`re-${props.size}`]: props.size }, props.class]"
        :model-value="model"
        :label="label"
        :show-warning-icon="props.showWarningIcon"
        :tooltip="tooltip"
        :rules="rules"
        :required="required"
        @update:model-value="handleSelect"
    >
        <template #control="fieldProps">
            <div v-if="bigButtons" class="re-big-buttons full-width">
                <!-- NOTE: need two levels of div to avoid margin issues due to Grid -->
                <div class="row" :class="rowClasses">
                    <div
                        v-for="(opt, idx) in compOptions"
                        :key="opt.value"
                        :class="colClassesComputed"
                        :aria-label="opt.arialLabel"
                    >
                        <transition
                            appear
                            enter-active-class="animated fadeIn slow"
                            leave-active-class="animated fadeOut slow"
                        >
                            <!-- NOTE: we need full-height to ensure same height across buttons, but might interfere with some uses -> potentially move to an option: -->
                            <div
                                v-if="$attrs.readonly && $slots['button-content']"
                                class="button-card full-width full-height"
                                :class="{
                                    active: isActive(opt),
                                    enabled: !$attrs.disable && !$attrs.readonly,
                                    'no-padding': !!props.noPadding,
                                }"
                            >
                                <slot
                                    name="button-content"
                                    :item="opt"
                                    :active="isActive(opt)"
                                    :idx="idx"
                                    readonly
                                />
                            </div>
                            <q-btn
                                v-else
                                class="button-card full-width full-height"
                                :class="{
                                    active: isActive(opt),
                                    enabled: !$attrs.disable && !$attrs.readonly,
                                    'no-padding': !!props.noPadding,
                                }"
                                no-caps
                                :disable="$attrs.disable || $attrs.readonly"
                                @click="fieldProps.emitValue(props.emitValue ? opt.value : opt)"
                            >
                                <slot name="button-content" :item="opt" :active="isActive(opt)" :idx="idx">
                                    <div
                                        class="items-center no-wrap q-mr-auto"
                                        :class="{
                                            row: !isStacked,
                                            column: isStacked,
                                            stacked: isStacked,
                                            'full-width': isStacked,
                                            'q-mx-auto':
                                                !opt.iconName &&
                                                !opt.iconSvgName &&
                                                !opt.picto &&
                                                $q.screen.gt.sm,
                                        }"
                                    >
                                        <div v-if="opt.iconName || opt.iconSvgName">
                                            <re-icon
                                                class="icon q-pa-md"
                                                :class="{ 're-md': $q.screen.xs }"
                                                :name="opt.iconName"
                                                :svg-name="opt.iconSvgName"
                                            />
                                        </div>
                                        <div v-if="opt.picto" class="q-pa-md row">
                                            <img class="col-xs-6 col-md-12" :src="opt.picto" />
                                        </div>
                                        <div
                                            class="col labels"
                                            :class="{
                                                'order-last': isStacked,
                                                selected: isActive(opt),
                                            }"
                                        >
                                            <h5
                                                :class="{
                                                    'text-left': !isStacked,
                                                }"
                                            >
                                                {{ opt.label }}
                                                <re-tooltip
                                                    v-if="opt.tooltip"
                                                    :label="opt.tooltip"
                                                    :is-warning="opt.tooltipIsWarning"
                                                />
                                            </h5>
                                            <p v-if="opt.caption" class="caption">
                                                {{ opt.caption }}
                                            </p>
                                        </div>
                                    </div>
                                </slot>
                            </q-btn>
                        </transition>
                    </div>
                </div>
            </div>
            <div v-else-if="props.isSeparated" class="full-width">
                <div class="re-separated row q-col-gutter-sm">
                    <div v-for="(opt, idx) in compOptions" :key="opt.value" :class="colClassesComputed">
                        <re-button
                            :no-wrap="false"
                            :class="{
                                [`type-${props.color}`]: !!props.color,
                                selected: isActive(opt),
                                'other-selected': isOtherSelected(opt),
                                fit: true,
                            }"
                            btn-classes="fit"
                            text-color="dark"
                            :color="
                                isActive(opt)
                                    ? props.color
                                        ? `${props.color}-bground`
                                        : 'primary-light'
                                    : 'white'
                            "
                            no-caps
                            :size="size"
                            :label="opt.label"
                            @click="fieldProps.emitValue(props.emitValue ? opt.value : opt)"
                        >
                            <slot name="button-content" :item="opt" :active="isActive(opt)" :idx="idx" />
                        </re-button>
                    </div>
                </div>
            </div>
            <q-btn-toggle
                v-else
                :model-value="fieldProps.modelValue"
                :class="{
                    're-btn-toggle': true,
                    [color]: !!color,
                    fit: wideOptions,
                    [`re-${props.size}`]: props.size,
                }"
                no-caps
                color="white"
                text-color="secondary"
                :toggle-color="color ? `${color}-light` : 'primary-light'"
                :toggle-text-color="color ?? 'primary'"
                unelevated
                :spread="$q.screen.lt.md || wideOptions"
                :options="compOptions"
                no-wrap
                :disable="$attrs.disable || $attrs.readonly"
                @update:model-value="fieldProps.emitValue"
            >
                <!-- prettier-ignore -->
                <template v-for="slotName in Object.keys($slots)" :key="slotName" #[slotName]="slotProps">
                    <slot :name="slotName" v-bind="slotProps ?? {}"></slot>
                </template>
            </q-btn-toggle>
        </template>
    </re-field>
</template>
<script setup>
import { computed } from 'vue'
import { useQuasar } from 'quasar'
import { useI18n } from 'vue-i18n'

const $q = useQuasar()
const { t } = useI18n()

const props = defineProps({
    isSeparated: {
        type: Boolean,
        default: false,
    },
    options: {
        type: Array,
        default: () => [],
    },
    // Display options as large card buttons:
    bigButtons: {
        type: Boolean,
        default: false,
    },
    size: {
        type: String,
        default: 'lg',
    },
    color: {
        type: String,
        default: undefined,
    },
    multiple: {
        type: Boolean,
        default: false,
    },
    wideOptions: {
        type: Boolean,
        default: false,
    },
    stacked: {
        type: Boolean,
        default: false,
    },
    isYesNo: {
        type: Boolean,
        default: false,
    },
    required: {
        type: Boolean,
        default: false,
    },
    label: {
        type: String,
        default: undefined,
    },
    tooltip: {
        type: String,
        default: undefined,
    },
    rules: {
        type: Array,
        default: () => [],
    },
    idkEnabled: {
        type: Boolean,
        default: false,
    },
    isHalfWidth: {
        type: Boolean,
        default: false,
    },
    rowClasses: {
        type: [String, Object, Array],
        default: 'justify-start q-col-gutter-md',
    },
    colClasses: {
        type: [String, Object, Array],
        default: undefined,
    },
    noPadding: {
        type: Boolean,
        default: false,
    },
    // NOTE: (!)emitValue implies (!)mapOptions
    emitValue: {
        type: Boolean,
        default: true,
    },
    class: {
        type: String,
        default: undefined,
    },
    showWarningIcon: {
        type: Boolean,
        default: false,
    },
})

const model = defineModel({ type: [String, Boolean, Object, Array, Number], default: undefined })

// Handle smooth transition of features from single-selection to multiple-selection:
if (props.multiple && typeof model.value !== 'object') model.value = [model.value]

// TODO: allow automatic model based on `selected` prop in options

// @select is emitted when an option is selected.
//  Event value will be the selected option and can be different
//  from the model value if `multiple` is true
// use @update:model-value to receive the new model value
const emits = defineEmits(['select', 'yes', 'no'])

const isStacked = computed(() => props.stacked ?? $q.screen.gt.sm)
const compOptions = computed(() => {
    if (props.isYesNo) {
        const opts = [
            { label: t('value.yes'), value: true, iconName: 'mdi-check' },
            { label: t('value.no'), value: false, iconName: 'mdi-close' },
        ]

        if (props.idkEnabled)
            opts.push({
                label: t('common.i_dont_know'),
                value: 'idk',
                iconName: 'mdi-help',
            })

        return opts
    }
    return props.options.filter((opt) => !opt.hidden)
})

const colClassesComputed = computed(() => {
    if (props.colClasses) return props.colClasses

    if (props.bigButtons)
        return [
            'col-xs-12',
            compOptions.value.length <= 2
                ? 'col-sm'
                : compOptions.value.length <= 3
                  ? 'col-sm-4'
                  : 'col-sm-4 col-lg-3 col-xl-2',
        ]

    if (props.isHalfWidth) {
        if (compOptions.value.length <= 3) return 'col-xs-12 col-sm-6 col-md-4 col-lg-4'
        return 'col-xs-12 col-sm-6 col-md-6 col-lg-4'
    }

    if (compOptions.value.length <= 3) return 'col-xs-12 col-sm-4 col-md-4 col-lg-2'
    return 'col-xs-12 col-sm-4 col-md-3 col-lg-2'
})

const isModelUndefined = computed(() => model.value === undefined || model.value === null)

const handleSelect = (val) => {
    if (props.isYesNo) {
        if (val === true) emits('yes')
        else if (val === false) emits('no')
    }
    if (props.multiple) {
        if (isModelUndefined.value) model.value = []
        else
            model.value = model.value.includes(val)
                ? model.value.filter((v) => v !== val)
                : [...model.value, val]
    } else {
        model.value = val
    } // If controlled (value provided), update is responsibility of parent

    emits('select', val)
}

if (props.multiple && isModelUndefined.value) model.value = []

const isActive = (opt) => {
    if (props.emitValue) {
        return props.multiple ? (model.value || []).includes(opt.value) : model.value === opt.value
    } else
        return props.multiple
            ? (model.value || []).map((v) => v.value).includes(opt.value)
            : model.value.value == opt.value
}
const isOtherSelected = (opt) => !isActive(opt) && !props.multiple && !isModelUndefined.value
</script>
i
<style lang="scss" scoped>
.re-multi-choice {
    :deep(.q-field--auto-height .q-field__control),
    :deep(.q-field--auto-height .q-field__native) {
        min-height: inherit !important;
    }

    :deep(.q-btn) {
        line-height: 1em;

        &:hover {
            .q-focus-helper {
                background: var(--q-primary);
            }
        }
    }
}

.re-separated {
    :deep(.q-btn) {
        font-weight: 700;
        color: var(--q-dark) !important;
        border: 1px solid $border;
        line-height: normal;
    }
    .selected :deep(.q-btn) {
        border: 1px solid var(--primary-border);
        color: var(--q-primary) !important;
        // TODO: create a global mixin for positive/negative/warning divs:
    }
    .selected.type-negative :deep(.q-btn) {
        border: 1px solid var(--q-negative);
        color: var(--q-negative) !important;
    }
    .other-selected :deep(.q-btn) {
        color: var(--q-secondary) !important;
    }
    .other-selected.type-negative :deep(.q-btn) {
        color: var(--q-negative) !important;
    }
}
// TODO: check if there's a cleaner way:
// :deep(.q-field--auto-height) {
//     align-items: start;
// }

.re-btn-toggle {
    background-color: $base;

    :deep(.q-btn) {
        line-height: 1em;
    }

    :deep(.q-btn[aria-pressed='true']) {
        border: 1px solid var(--primary-border);
        font-weight: 700;
    }

    :deep(.q-btn[aria-pressed='false']) {
        border: 1px solid $border;
        &:nth-child(even):not(:last-child):not(:first-child) {
            border-right: none;
        }
        &:nth-child(even) {
            border-left: none;
        }
    }

    &.warning {
        :deep(.q-btn[aria-pressed='true']) {
            border-color: $warning;
            background-color: var(--warning-bground) !important;
        }
        :deep(.q-btn[aria-pressed='false']) {
            border-color: $warning !important;
        }
    }

    &.negative {
        :deep(.q-btn[aria-pressed='true']) {
            border-color: var(--q-negative);
            background-color: var(--negative-bground) !important;
        }
        :deep(.q-btn[aria-pressed='false']) {
            border-color: var(--q-negative) !important;
        }
    }

    &.positive {
        :deep(.q-btn[aria-pressed='true']) {
            border-color: $positive;
            background-color: var(--positive-bground) !important;
        }
        :deep(.q-btn[aria-pressed='false']) {
            border-color: $positive !important;
        }
    }
}

.re-big-buttons {
    .button-card {
        min-height: 150px;
        border: 1px solid $border;
        padding: min(3vw, 3em) min(3vw, 3em);
        color: var(--q-dark);
        background-color: $base;
        border-radius: $button-border-radius;

        &.no-padding {
            padding: 0;
        }

        .icon {
            border: 1px solid $border;
            border-radius: 50%;
            background-color: var(--secondary-bground);
            color: var(--q-secondary);
            margin-right: min(1.5vw, 1.5em);
        }

        .labels {
            h5 {
                margin: 0;
                color: var(--q-dark);
                text-align: left;
            }
            &.selected h5 {
                color: var(--q-primary);
            }
            p.caption {
                margin: 0.75em 0 0 0;
                color: var(--q-secondary);
                text-align: left;
                font-size: 1rem;
                font-weight: normal;
            }
        }
        .stacked .labels p {
            text-align: center;
        }

        &.enabled:hover,
        &.active {
            border-color: var(--q-primary);

            &:deep(.q-btn)::before {
                box-shadow: 0px 2px 14px 0px rgba(0, 156, 255, 0.2);
            }

            .icon {
                background-color: var(--primary-bground);
                color: var(--q-primary);
                border-color: var(--primary-light);
            }

            p.text-body1 {
                color: var(--q-primary);
            }
        }
    }
}

body.screen--xs .button-card {
    min-height: unset;
}
</style>
