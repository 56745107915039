<template>
    <!-- TODO: find a way to aviod template repetition? -->
    <div v-if="props.wrapped" style="position: relative; min-height: 200px; min-width: 200px">
        <q-inner-loading showing v-bind="$attrs">
            <q-spinner-gears size="100px" color="primary" />
            <div class="q-pa-md">
                <slot></slot>
            </div>
        </q-inner-loading>
    </div>
    <q-inner-loading v-else showing v-bind="$attrs">
        <q-spinner-gears size="100px" color="primary" />
        <div class="q-pa-md">
            <slot></slot>
        </div>
    </q-inner-loading>
</template>
<script setup>
const props = defineProps({
    wrapped: {
        type: Boolean,
        default: false,
    },
})
</script>
