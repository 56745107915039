export const createCatalogRoute = () => ({
    path: '/catalog',
    name: 'catalog',
    redirect: () => {
        return { name: 'catalog-start' }
    },
    component: () => import('@/apps/Catalog/Catalog.vue'),
    children: [
        {
            path: 'start',
            name: 'catalog-start',
            component: () => import('@/apps/Catalog/Start.vue'),
        },
        {
            path: 'view',
            name: 'catalog-view',
            component: () => import('@/apps/Catalog/View/View.vue'),
            children: [
                {
                    path: '3d',
                    name: 'catalog-view-3d',
                    component: () => import('@/apps/Catalog/View/Map/3DMap.vue'),
                },
                {
                    path: '2d',
                    name: 'catalog-view-2d',
                    component: () => import('@/apps/Catalog/View/Map/2DMap.vue'),
                },
                {
                    path: 'street-view',
                    name: 'catalog-view-street-view',
                    component: () => import('@/apps/Catalog/View/Map/StreetView.vue'),
                },
            ],
        },
        {
            path: 'report',
            name: 'catalog-report',
            component: () => import('@/apps/Catalog/Report.vue'),
        },
    ],
})
