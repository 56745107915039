// This file is generated automatically, edit by running update_config.py or make masterfile
/* eslint-disable */
export const config =[
    {
        "modules": [
            "core",
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment"
        ],
        "functionality": "feature",
        "type": "string",
        "unit": "",
        "location": "address",
        "name": "full_address",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment"
        ],
        "functionality": "feature",
        "type": "date",
        "unit": "",
        "location": "valuation",
        "name": "date",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "date",
        "unit": "",
        "location": "assessment",
        "name": "date",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "enum",
        "unit": "",
        "location": "assessment",
        "name": "type",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "enum",
        "unit": "",
        "location": "valuation",
        "name": "type",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": [
            "avm",
            "dvm",
            "ovm"
        ],
        "sections": []
    },
    {
        "modules": [
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment"
        ],
        "functionality": "feature",
        "type": "string",
        "unit": "",
        "location": "request",
        "name": "references",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment"
        ],
        "functionality": "feature",
        "type": "string",
        "unit": "",
        "location": "request",
        "name": "valuation_request_ref",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment"
        ],
        "functionality": "feature",
        "type": "string",
        "unit": "",
        "location": "request",
        "name": "customer_ref",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [
            "core"
        ],
        "building_type_scope": [
            "house",
            "apartment"
        ],
        "functionality": "text",
        "type": "string",
        "unit": "",
        "location": "section",
        "name": "reconstruction_value",
        "search_dict_name": "",
        "icon": "ic-reconstruction",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [
            "core"
        ],
        "building_type_scope": [
            "house",
            "apartment"
        ],
        "functionality": "text",
        "type": "string",
        "unit": "",
        "location": "section",
        "name": "address_location",
        "search_dict_name": "",
        "icon": "ic-location",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [
            "core"
        ],
        "building_type_scope": [
            "house",
            "apartment"
        ],
        "functionality": "text",
        "type": "string",
        "unit": "",
        "location": "section",
        "name": "secondary_buildings",
        "search_dict_name": "",
        "icon": "ic-cube",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [
            "core"
        ],
        "building_type_scope": [
            "house",
            "apartment"
        ],
        "functionality": "text",
        "type": "string",
        "unit": "",
        "location": "section",
        "name": "economic_activity",
        "search_dict_name": "",
        "icon": "ic-economic",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [
            "core"
        ],
        "building_type_scope": [
            "house",
            "apartment"
        ],
        "functionality": "text",
        "type": "string",
        "unit": "",
        "location": "section",
        "name": "roof",
        "search_dict_name": "",
        "icon": "ic-roof",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [
            "core"
        ],
        "building_type_scope": [
            "house",
            "apartment"
        ],
        "functionality": "text",
        "type": "string",
        "unit": "",
        "location": "section",
        "name": "transaction",
        "search_dict_name": "",
        "icon": "ic-economic",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "text",
        "type": "string",
        "unit": "",
        "location": "section",
        "name": "general",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "text",
        "type": "string",
        "unit": "",
        "location": "section",
        "name": "report",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "text",
        "type": "string",
        "unit": "",
        "location": "section",
        "name": "application",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "text",
        "type": "string",
        "unit": "",
        "location": "section",
        "name": "dashboard",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "text",
        "type": "string",
        "unit": "",
        "location": "section",
        "name": "dispatcher",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "text",
        "type": "string",
        "unit": "",
        "location": "section",
        "name": "playground",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "text",
        "type": "string",
        "unit": "",
        "location": "section",
        "name": "user-admin",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "text",
        "type": "string",
        "unit": "",
        "location": "section",
        "name": "configuration",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "text",
        "type": "string",
        "unit": "",
        "location": "section",
        "name": "apps",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "text",
        "type": "string",
        "unit": "",
        "location": "section",
        "name": "results",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "text",
        "type": "string",
        "unit": "",
        "location": "section",
        "name": "flood_regular",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "text",
        "type": "string",
        "unit": "",
        "location": "section",
        "name": "flood_insurance_exclusion",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "text",
        "type": "string",
        "unit": "",
        "location": "section",
        "name": "flood_general",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "text",
        "type": "string",
        "unit": "",
        "location": "section",
        "name": "helpcenter",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "text",
        "type": "string",
        "unit": "",
        "location": "section",
        "name": "faq-search-results",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [
            "core"
        ],
        "building_type_scope": [
            "house",
            "apartment"
        ],
        "functionality": "text",
        "type": "string",
        "unit": "",
        "location": "section",
        "name": "warnings",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment"
        ],
        "functionality": "feature",
        "type": "string",
        "unit": "",
        "location": "request",
        "name": "bank",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment"
        ],
        "functionality": "feature",
        "type": "string",
        "unit": "",
        "location": "valuation",
        "name": "valuer",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment"
        ],
        "functionality": "feature",
        "type": "string",
        "unit": "",
        "location": "valuation",
        "name": "monitor",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment"
        ],
        "functionality": "feature",
        "type": "string",
        "unit": "",
        "location": "request",
        "name": "customer",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment"
        ],
        "functionality": "feature",
        "type": "enum",
        "unit": "",
        "location": "request",
        "name": "purpose",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": [
            "new_loan",
            "revaluation",
            "refinancing",
            "npl"
        ],
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "enum",
        "unit": "",
        "location": "request",
        "name": "package",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": [
            "classic",
            "renovation_light",
            "renovation_full",
            "new_construction"
        ],
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "text",
        "type": "string",
        "unit": "",
        "location": "section",
        "name": "renovation",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [
            "house",
            "apartment"
        ],
        "functionality": "feature",
        "type": "float",
        "unit": "euro",
        "location": "features",
        "name": "renovation_cost",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "general_info"
        ]
    },
    {
        "modules": [],
        "building_type_scope": [
            "house"
        ],
        "functionality": "feature",
        "type": "float",
        "unit": "euro",
        "location": "features",
        "name": "energetic_renovation_cost",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "general_info"
        ]
    },
    {
        "modules": [],
        "building_type_scope": [
            "house"
        ],
        "functionality": "feature",
        "type": "float",
        "unit": "euro",
        "location": "features",
        "name": "energetic_renovation_added_value",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [
            "house",
            "apartment"
        ],
        "functionality": "feature",
        "type": "float",
        "unit": "euro",
        "location": "features",
        "name": "construction_cost",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "general_info"
        ]
    },
    {
        "modules": [],
        "building_type_scope": [
            "house",
            "apartment"
        ],
        "functionality": "feature",
        "type": "float",
        "unit": "euro",
        "location": "features",
        "name": "parcel_cost",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "general_info"
        ]
    },
    {
        "modules": [
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment",
            "construction_plot"
        ],
        "functionality": "text",
        "type": "string",
        "unit": "",
        "location": "section",
        "name": "general_info",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "enum",
        "unit": "",
        "location": "features",
        "name": "building_class",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": [
            "new_building",
            "existing_building"
        ],
        "sections": []
    },
    {
        "modules": [
            "core",
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment",
            "construction_plot"
        ],
        "functionality": "feature",
        "type": "enum",
        "unit": "",
        "location": "features",
        "name": "f_building_type",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": [
            "house",
            "apartment",
            "new_house",
            "new_apartment",
            "construction_plot"
        ],
        "sections": [
            "general_info",
            "building",
            "c_building",
            "flood_general"
        ]
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "int",
        "unit": "",
        "location": "features",
        "name": "f_touching_sides",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": [
            "attached",
            "detached",
            "town_house",
            "condominium"
        ],
        "sections": []
    },
    {
        "modules": [
            "core",
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "new_house"
        ],
        "functionality": "feature",
        "type": "enum",
        "unit": "",
        "location": "features",
        "name": "f_number_of_facades",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": [
            "attached",
            "semi",
            "detached"
        ],
        "sections": [
            "general_info",
            "c_building"
        ]
    },
    {
        "modules": [
            "core",
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment"
        ],
        "functionality": "feature",
        "type": "enum",
        "unit": "",
        "location": "features",
        "name": "f_number_of_facades_or_type",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [
            "core",
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "new_house",
            "construction_plot"
        ],
        "functionality": "feature",
        "type": "float",
        "unit": "m2",
        "location": "features",
        "name": "f_parcel_area",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "parcels_and_buildings",
            "parcels",
            "c_parcels"
        ]
    },
    {
        "modules": [
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment",
            "construction_plot"
        ],
        "functionality": "text",
        "type": "string",
        "unit": "",
        "location": "section",
        "name": "parcels_and_buildings",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [
            "core"
        ],
        "building_type_scope": [
            "house",
            "apartment"
        ],
        "functionality": "text",
        "type": "string",
        "unit": "",
        "location": "section",
        "name": "c_parcels",
        "search_dict_name": "",
        "icon": "ic-environment",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [
            "core"
        ],
        "building_type_scope": [
            "house",
            "apartment"
        ],
        "functionality": "text",
        "type": "string",
        "unit": "",
        "location": "section",
        "name": "c_building",
        "search_dict_name": "",
        "icon": "ic-building",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [
            "core",
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment",
            "construction_plot"
        ],
        "functionality": "feature",
        "type": "float",
        "unit": "m2",
        "location": "features",
        "name": "f_building_area",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "parcels_and_buildings",
            "c_building"
        ]
    },
    {
        "modules": [],
        "building_type_scope": [
            "house",
            "apartment"
        ],
        "functionality": "feature",
        "type": "string",
        "unit": "",
        "location": "features",
        "name": "f_others",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [
            "core",
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house"
        ],
        "functionality": "feature",
        "type": "int",
        "unit": "",
        "location": "features",
        "name": "f_x_annexes",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "parcels_and_buildings",
            "parcels",
            "secondary_buildings"
        ]
    },
    {
        "modules": [
            "core",
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house"
        ],
        "functionality": "feature",
        "type": "float",
        "unit": "m2",
        "location": "features",
        "name": "f_annexes_area",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "parcels_and_buildings",
            "parcels",
            "secondary_buildings"
        ]
    },
    {
        "modules": [
            "core",
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house"
        ],
        "functionality": "feature",
        "type": "float",
        "unit": "m2",
        "location": "features",
        "name": "f_area_largest_annex",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "secondary_buildings"
        ]
    },
    {
        "modules": [
            "core"
        ],
        "building_type_scope": [
            "house"
        ],
        "functionality": "feature",
        "type": "array_of_float",
        "unit": "m",
        "location": "features",
        "name": "distances_annexes",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "secondary_buildings"
        ]
    },
    {
        "modules": [
            "core",
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment",
            "construction_plot"
        ],
        "functionality": "feature",
        "type": "custom_parcel",
        "unit": "",
        "location": "features",
        "name": "parcel_ids",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "parcels_and_buildings",
            "flood_general"
        ]
    },
    {
        "modules": [
            "core",
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment",
            "construction_plot"
        ],
        "functionality": "feature",
        "type": "float",
        "unit": "degree",
        "location": "features",
        "name": "f_lng",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "address_location"
        ]
    },
    {
        "modules": [
            "core",
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment",
            "construction_plot"
        ],
        "functionality": "feature",
        "type": "float",
        "unit": "degree",
        "location": "features",
        "name": "f_lat",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "address_location"
        ]
    },
    {
        "modules": [
            "core"
        ],
        "building_type_scope": [
            "house",
            "apartment"
        ],
        "functionality": "feature",
        "type": "int",
        "unit": "",
        "location": "features",
        "name": "f_number_of_addresses",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "address_location"
        ]
    },
    {
        "modules": [
            "core",
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment"
        ],
        "functionality": "text",
        "type": "string",
        "unit": "",
        "location": "section",
        "name": "building",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [
            "core",
            "dvm",
            "ovm",
            "ers"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment"
        ],
        "functionality": "feature",
        "type": "float",
        "unit": "m2",
        "location": "features",
        "name": "f_living_area",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "building",
            "c_building",
            "house_info"
        ]
    },
    {
        "modules": [
            "core"
        ],
        "building_type_scope": [
            "apartment"
        ],
        "functionality": "feature",
        "type": "float",
        "unit": "m2",
        "location": "features",
        "name": "f_approx_living_area",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "c_building"
        ]
    },
    {
        "modules": [
            "core"
        ],
        "building_type_scope": [
            "house"
        ],
        "functionality": "feature",
        "type": "int",
        "unit": "",
        "location": "features",
        "name": "n_rooms",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "c_building"
        ]
    },
    {
        "modules": [
            "core"
        ],
        "building_type_scope": [
            "house"
        ],
        "functionality": "feature",
        "type": "int",
        "unit": "",
        "location": "features",
        "name": "approx_n_rooms",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "c_building"
        ]
    },
    {
        "modules": [
            "core"
        ],
        "building_type_scope": [
            "house"
        ],
        "functionality": "feature",
        "type": "float",
        "unit": "euro",
        "location": "features",
        "name": "transaction_value",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "transaction"
        ]
    },
    {
        "modules": [
            "core"
        ],
        "building_type_scope": [
            "house"
        ],
        "functionality": "feature",
        "type": "date",
        "unit": "",
        "location": "features",
        "name": "transaction_date",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "transaction"
        ]
    },
    {
        "modules": [
            "core"
        ],
        "building_type_scope": [
            "apartment"
        ],
        "functionality": "feature",
        "type": "float",
        "unit": "euro/m2",
        "location": "features",
        "name": "approx_price_sqm",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "transaction"
        ]
    },
    {
        "modules": [
            "core"
        ],
        "building_type_scope": [
            "house"
        ],
        "functionality": "feature",
        "type": "float",
        "unit": "kgco2/m2year",
        "location": "features",
        "name": "co2_sqm",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "energy"
        ]
    },
    {
        "modules": [
            "core"
        ],
        "building_type_scope": [
            "house"
        ],
        "functionality": "feature",
        "type": "string",
        "unit": "",
        "location": "features",
        "name": "co2_label",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "energy"
        ]
    },
    {
        "modules": [
            "core"
        ],
        "building_type_scope": [
            "apartment"
        ],
        "functionality": "feature",
        "type": "float",
        "unit": "kwh/m2year",
        "location": "features",
        "name": "f_approx_epc",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "energy"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment"
        ],
        "functionality": "feature",
        "type": "enum",
        "unit": "",
        "location": "dvm_features",
        "name": "style_of_house",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": [
            "traditional",
            "modern",
            "contemporary",
            "mediterranean",
            "other"
        ],
        "sections": [
            "building_structure"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm",
            "ers"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment"
        ],
        "functionality": "feature",
        "type": "int",
        "unit": "",
        "location": "features",
        "name": "f_construction_year",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "building",
            "c_building",
            "house_info"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment"
        ],
        "functionality": "feature",
        "type": "numeric",
        "unit": "kwh/m2year",
        "location": "features",
        "name": "f_epc",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "energy"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment"
        ],
        "functionality": "feature",
        "type": "string",
        "unit": "",
        "location": "features",
        "name": "f_epc_label",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": [
            "A++",
            "A+",
            "A",
            "B",
            "C",
            "D",
            "E",
            "F",
            "G"
        ],
        "sections": [
            "energy",
            "house_info"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment"
        ],
        "functionality": "feature",
        "type": "numeric",
        "unit": "kwh/m2year",
        "location": "features",
        "name": "f_epc_numeric",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "building"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "apartment",
            "new_apartment"
        ],
        "functionality": "feature",
        "type": "int",
        "unit": "",
        "location": "features",
        "name": "f_bedrooms",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "building"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "apartment",
            "new_apartment"
        ],
        "functionality": "feature",
        "type": "enum",
        "unit": "",
        "location": "features",
        "name": "f_floor",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": [
            "ground_floor",
            "1",
            "2",
            "3",
            "4",
            ">4"
        ],
        "sections": [
            "building"
        ]
    },
    {
        "modules": [],
        "building_type_scope": [
            "apartment",
            "new_apartment"
        ],
        "functionality": "feature",
        "type": "int",
        "unit": "",
        "location": "features",
        "name": "f_bottom_floor",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "building"
        ]
    },
    {
        "modules": [],
        "building_type_scope": [
            "apartment",
            "new_apartment"
        ],
        "functionality": "feature",
        "type": "int",
        "unit": "",
        "location": "features",
        "name": "f_top_floor",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "building"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "apartment",
            "new_apartment"
        ],
        "functionality": "feature",
        "type": "array_of_int",
        "unit": "",
        "location": "features",
        "name": "f_floors",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "building"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "apartment",
            "new_apartment"
        ],
        "functionality": "feature",
        "type": "boolean",
        "unit": "",
        "location": "features",
        "name": "f_balcony_present",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "building"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "apartment",
            "new_apartment"
        ],
        "functionality": "feature",
        "type": "boolean",
        "unit": "",
        "location": "features",
        "name": "f_garage_present",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "building"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "apartment",
            "new_apartment"
        ],
        "functionality": "feature",
        "type": "int",
        "unit": "",
        "location": "features",
        "name": "f_n_closed_garage",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "building"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "new_house",
            "apartment",
            "new_apartment"
        ],
        "functionality": "feature",
        "type": "custom_garage",
        "unit": "",
        "location": "ovm_features",
        "name": "garages",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "parcels"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "new_house",
            "apartment",
            "new_apartment"
        ],
        "functionality": "feature",
        "type": "custom_garage",
        "unit": "",
        "location": "ovm_features",
        "name": "parkings",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "parcels"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "new_house"
        ],
        "functionality": "text",
        "type": "string",
        "unit": "",
        "location": "section",
        "name": "secondary_buildings2",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "new_house"
        ],
        "functionality": "feature",
        "type": "boolean",
        "unit": "",
        "location": "ovm_features",
        "name": "pool_house",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "secondary_buildings2"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "new_house"
        ],
        "functionality": "feature",
        "type": "int",
        "unit": "m2",
        "location": "ovm_features",
        "name": "pool_house_area",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "secondary_buildings2"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "new_house"
        ],
        "functionality": "feature",
        "type": "boolean",
        "unit": "",
        "location": "ovm_features",
        "name": "barn",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "secondary_buildings2"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "new_house"
        ],
        "functionality": "feature",
        "type": "int",
        "unit": "m2",
        "location": "ovm_features",
        "name": "barn_area",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "secondary_buildings2"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "new_house"
        ],
        "functionality": "feature",
        "type": "boolean",
        "unit": "",
        "location": "ovm_features",
        "name": "stables",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "secondary_buildings2"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "new_house"
        ],
        "functionality": "feature",
        "type": "int",
        "unit": "m2",
        "location": "ovm_features",
        "name": "stables_area",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "secondary_buildings2"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "apartment",
            "new_apartment"
        ],
        "functionality": "feature",
        "type": "int",
        "unit": "",
        "location": "features",
        "name": "f_n_parking_spot",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "building"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "apartment",
            "new_apartment"
        ],
        "functionality": "feature",
        "type": "boolean",
        "unit": "",
        "location": "features",
        "name": "f_cellar_attic",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "building"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "apartment",
            "new_apartment"
        ],
        "functionality": "feature",
        "type": "enum",
        "unit": "",
        "location": "features",
        "name": "f_garden_access",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": [
            "none",
            "\nprivate",
            "common",
            "private+common"
        ],
        "sections": [
            "building"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "apartment",
            "new_apartment"
        ],
        "functionality": "feature",
        "type": "boolean",
        "unit": "",
        "location": "features",
        "name": "f_garden_common",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "building"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "apartment",
            "new_apartment"
        ],
        "functionality": "feature",
        "type": "boolean",
        "unit": "",
        "location": "features",
        "name": "f_garden_private",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "building"
        ]
    },
    {
        "modules": [
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment"
        ],
        "functionality": "text",
        "type": "string",
        "unit": "",
        "location": "section",
        "name": "building_structure",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [
            "core",
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment"
        ],
        "functionality": "feature",
        "type": "float",
        "unit": "m3",
        "location": "features",
        "name": "f_volume",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "building_structure",
            "c_building"
        ]
    },
    {
        "modules": [],
        "building_type_scope": [
            "house"
        ],
        "functionality": "feature",
        "type": "float",
        "unit": "m2",
        "location": "features",
        "name": "exterior_wall_area",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "c_building"
        ]
    },
    {
        "modules": [
            "ovm"
        ],
        "building_type_scope": [
            "apartment",
            "new_apartment"
        ],
        "functionality": "text",
        "type": "string",
        "unit": "",
        "location": "section",
        "name": "common_parts",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [
            "ovm"
        ],
        "building_type_scope": [
            "apartment",
            "new_apartment"
        ],
        "functionality": "feature",
        "type": "enum",
        "unit": "",
        "location": "ovm_features",
        "name": "common_parts_state",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": [
            "maintenance_required",
            "good",
            "very_good"
        ],
        "sections": [
            "common_parts"
        ]
    },
    {
        "modules": [
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "new_house",
            "apartment",
            "new_apartment"
        ],
        "functionality": "feature",
        "type": "enum",
        "unit": "",
        "location": "ovm_features",
        "name": "interior_state",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": [
            "maintenance_required",
            "good",
            "very_good"
        ],
        "sections": [
            "interior"
        ]
    },
    {
        "modules": [
            "ovm"
        ],
        "building_type_scope": [
            "apartment",
            "new_apartment"
        ],
        "functionality": "feature",
        "type": "int",
        "unit": "percent",
        "location": "ovm_features",
        "name": "apartment_quotity",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "common_parts"
        ]
    },
    {
        "modules": [
            "ovm"
        ],
        "building_type_scope": [
            "apartment",
            "new_apartment"
        ],
        "functionality": "feature",
        "type": "float",
        "unit": "euro",
        "location": "ovm_features",
        "name": "reserve_savings",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "common_parts"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment"
        ],
        "functionality": "feature",
        "type": "enum",
        "unit": "",
        "location": "dvm_features",
        "name": "exterior_state",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": [
            "maintenance_required",
            "good",
            "very_good"
        ],
        "sections": [
            "building_structure"
        ]
    },
    {
        "modules": [
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment"
        ],
        "functionality": "feature",
        "type": "multi_enum_open",
        "unit": "",
        "location": "ovm_features",
        "name": "comfort",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": [
            "security",
            "water_softener",
            "air_con",
            "elevator",
            "domotics",
            "storage",
            "rain_water",
            "hot_tub"
        ],
        "sections": [
            "interior"
        ]
    },
    {
        "modules": [
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment"
        ],
        "functionality": "feature",
        "type": "multi_enum_open",
        "unit": "",
        "location": "ovm_features",
        "name": "common_comfort",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": [
            "security",
            "water_softener",
            "air_con",
            "elevator",
            "domotics",
            "storage",
            "rain_water",
            "hot_tub"
        ],
        "sections": [
            "interior"
        ]
    },
    {
        "modules": [
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment"
        ],
        "functionality": "feature",
        "type": "multi_enum_open",
        "unit": "",
        "location": "ovm_features",
        "name": "interior_positive",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "interior"
        ]
    },
    {
        "modules": [
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment"
        ],
        "functionality": "feature",
        "type": "multi_enum_open",
        "unit": "",
        "location": "ovm_features",
        "name": "interior_negative",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "interior"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment"
        ],
        "functionality": "feature",
        "type": "multi_enum_open",
        "unit": "",
        "location": "dvm_features",
        "name": "facade_material",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": [
            "cinder_blocks",
            "wood",
            "plaster",
            "natural_stone",
            "metal",
            "concrete",
            "brick"
        ],
        "sections": [
            "building_structure"
        ]
    },
    {
        "modules": [
            "core",
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment"
        ],
        "functionality": "feature",
        "type": "float",
        "unit": "m2",
        "location": "features",
        "name": "f_roof_area",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "building_structure",
            "roof"
        ]
    },
    {
        "modules": [
            "core",
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment"
        ],
        "functionality": "feature",
        "type": "float",
        "unit": "degree",
        "location": "features",
        "name": "f_mean_tilt",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "building_structure",
            "roof"
        ]
    },
    {
        "modules": [
            "core",
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment"
        ],
        "functionality": "feature",
        "type": "float",
        "unit": "percent",
        "location": "features",
        "name": "f_percent_of_roof_flat",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "building_structure",
            "roof"
        ]
    },
    {
        "modules": [
            "core"
        ],
        "building_type_scope": [
            "house",
            "apartment"
        ],
        "functionality": "feature",
        "type": "float",
        "unit": "m",
        "location": "features",
        "name": "f_min_height",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "building_structure",
            "roof"
        ]
    },
    {
        "modules": [
            "core"
        ],
        "building_type_scope": [
            "house",
            "apartment"
        ],
        "functionality": "feature",
        "type": "float",
        "unit": "m",
        "location": "features",
        "name": "f_max_height",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "building_structure",
            "roof"
        ]
    },
    {
        "modules": [
            "core"
        ],
        "building_type_scope": [
            "house",
            "apartment"
        ],
        "functionality": "feature",
        "type": "int",
        "unit": "",
        "location": "features",
        "name": "f_n_parts",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "building_structure",
            "c_building"
        ]
    },
    {
        "modules": [
            "core"
        ],
        "building_type_scope": [
            "house",
            "apartment"
        ],
        "functionality": "feature",
        "type": "int",
        "unit": "",
        "location": "features",
        "name": "f_n_roof_panes",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "building_structure",
            "roof"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment"
        ],
        "functionality": "feature",
        "type": "multi_enum_open",
        "unit": "",
        "location": "dvm_features",
        "name": "roof_material",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": [
            "tiles",
            "bitumen_roofing",
            "metal",
            "slate_shingles",
            "black_concrete_tiles",
            "thatched",
            "asbestos",
            "green_roof"
        ],
        "sections": [
            "building_structure"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "new_house"
        ],
        "functionality": "feature",
        "type": "int",
        "unit": "",
        "location": "dvm_features",
        "name": "n_roof_windows",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "building_structure"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "new_house"
        ],
        "functionality": "feature",
        "type": "int",
        "unit": "",
        "location": "dvm_features",
        "name": "n_roof_dormers",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "building_structure"
        ]
    },
    {
        "modules": [
            "AVM"
        ],
        "building_type_scope": [
            "House & Apartment"
        ],
        "functionality": "feature",
        "type": "boolean",
        "unit": "",
        "location": "dvm_features",
        "name": "solar_panel",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "new_house"
        ],
        "functionality": "feature",
        "type": "int",
        "unit": "",
        "location": "dvm_features",
        "name": "solar_panel_area",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "building_structure"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment"
        ],
        "functionality": "text",
        "type": "string",
        "unit": "",
        "location": "section",
        "name": "building_purpose_classification",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment"
        ],
        "functionality": "feature",
        "type": "boolean",
        "unit": "",
        "location": "dvm_features",
        "name": "f_building_listed",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "building_purpose_classification"
        ]
    },
    {
        "modules": [
            "core",
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment"
        ],
        "functionality": "feature",
        "type": "int",
        "unit": "",
        "location": "features",
        "name": "f_x_matched_entities",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "economic_activity"
        ]
    },
    {
        "modules": [
            "core",
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment"
        ],
        "functionality": "feature",
        "type": "enum",
        "unit": "",
        "location": "features",
        "name": "c_economic_activity_class",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": [
            "home_liberal_profession_or_office",
            "home_other_professional_activity",
            "home"
        ],
        "sections": [
            "economic_activity"
        ]
    },
    {
        "modules": [
            "core",
            "avm",
            "dvm"
        ],
        "building_type_scope": [
            "house",
            "apartment"
        ],
        "functionality": "feature",
        "type": "enum",
        "unit": "",
        "location": "features",
        "name": "c_economic_activity_level",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": [
            "no_economic_activity",
            "activity_unlikely",
            "activity_possible",
            "activity_likely",
            "activity_very_likely"
        ],
        "sections": [
            "economic_activity",
            "building_purpose_classification"
        ]
    },
    {
        "modules": [
            "core",
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment",
            "construction_plot"
        ],
        "functionality": "text",
        "type": "string",
        "unit": "",
        "location": "section",
        "name": "parcels",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [
            "core",
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "new_house"
        ],
        "functionality": "feature",
        "type": "float",
        "unit": "m2",
        "location": "features",
        "name": "f_garden_area",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "parcels",
            "c_parcels"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment",
            "construction_plot"
        ],
        "functionality": "feature",
        "type": "enum",
        "unit": "",
        "location": "dvm_features",
        "name": "garden_orientation",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": [
            "n",
            "ne",
            "e",
            "se",
            "s",
            "sw",
            "w",
            "nw"
        ],
        "sections": [
            "parcels"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment",
            "construction_plot"
        ],
        "functionality": "feature",
        "type": "enum",
        "unit": "",
        "location": "dvm_features",
        "name": "garden_arrangement",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": [
            "limited",
            "good",
            "very_good"
        ],
        "sections": [
            "parcels"
        ]
    },
    {
        "modules": [
            "ovm"
        ],
        "building_type_scope": [
            "apartment",
            "new_apartment"
        ],
        "functionality": "feature",
        "type": "enum",
        "unit": "",
        "location": "dvm_features",
        "name": "garden_common_arrangement",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": [
            "limited",
            "good",
            "very_good"
        ],
        "sections": [
            "parcels"
        ]
    },
    {
        "modules": [
            "core",
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment"
        ],
        "functionality": "feature",
        "type": "boolean",
        "unit": "",
        "location": "dvm_features",
        "name": "f_swimming_pool",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "parcels",
            "c_parcels"
        ]
    },
    {
        "modules": [
            "core",
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment"
        ],
        "functionality": "feature",
        "type": "float",
        "unit": "m2",
        "location": "dvm_features",
        "name": "f_swimming_pool_area",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "parcels",
            "c_parcels"
        ]
    },
    {
        "modules": [
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment"
        ],
        "functionality": "feature",
        "type": "fixed",
        "unit": "",
        "location": "fixed",
        "name": "parcels_and_building_shapes",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": [
            "details_previous_page"
        ],
        "sections": [
            "parcels"
        ]
    },
    {
        "modules": [
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "new_house",
            "new_apartment",
            "construction_plot"
        ],
        "functionality": "feature",
        "type": "fixed",
        "unit": "",
        "location": "fixed",
        "name": "parcels_shapes",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": [
            "details_previous_page"
        ],
        "sections": [
            "parcels"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment",
            "construction_plot"
        ],
        "functionality": "text",
        "type": "string",
        "unit": "",
        "location": "section",
        "name": "surroundings",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment",
            "construction_plot"
        ],
        "functionality": "text",
        "type": "string",
        "unit": "",
        "location": "section",
        "name": "mobility",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment"
        ],
        "functionality": "text",
        "type": "string",
        "unit": "",
        "location": "section",
        "name": "interior",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment",
            "construction_plot"
        ],
        "functionality": "feature",
        "type": "enum",
        "unit": "",
        "location": "dvm_features",
        "name": "environment",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": [
            "below_average",
            "average",
            "above_average"
        ],
        "sections": [
            "surroundings"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment",
            "construction_plot"
        ],
        "functionality": "feature",
        "type": "enum",
        "unit": "",
        "location": "dvm_features",
        "name": "public_transport",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": [
            "below_average",
            "average",
            "above_average"
        ],
        "sections": [
            "mobility"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment",
            "construction_plot"
        ],
        "functionality": "feature",
        "type": "enum",
        "unit": "",
        "location": "dvm_features",
        "name": "education",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": [
            "below_average",
            "average",
            "above_average"
        ],
        "sections": [
            "mobility"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment",
            "construction_plot"
        ],
        "functionality": "feature",
        "type": "enum",
        "unit": "",
        "location": "dvm_features",
        "name": "recreation",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": [
            "below_average",
            "average",
            "above_average"
        ],
        "sections": [
            "mobility"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment",
            "construction_plot"
        ],
        "functionality": "feature",
        "type": "enum",
        "unit": "",
        "location": "dvm_features",
        "name": "shops",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": [
            "below_average",
            "average",
            "above_average"
        ],
        "sections": [
            "mobility"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment",
            "construction_plot"
        ],
        "functionality": "feature",
        "type": "enum",
        "unit": "",
        "location": "dvm_features",
        "name": "healthcare",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": [
            "below_average",
            "average",
            "above_average"
        ],
        "sections": [
            "mobility"
        ]
    },
    {
        "modules": [
            "core",
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment",
            "construction_plot"
        ],
        "functionality": "feature",
        "type": "float",
        "unit": "m",
        "location": "features",
        "name": "f_distance_to_street",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "surroundings"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment",
            "construction_plot"
        ],
        "functionality": "feature",
        "type": "enum",
        "unit": "",
        "location": "dvm_features",
        "name": "direct_traffic_level",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": [
            "low",
            "medium",
            "high"
        ],
        "sections": [
            "surroundings"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment",
            "construction_plot"
        ],
        "functionality": "feature",
        "type": "enum",
        "unit": "",
        "location": "dvm_features",
        "name": "indirect_traffic_level",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": [
            "low",
            "medium",
            "high"
        ],
        "sections": [
            "surroundings"
        ]
    },
    {
        "modules": [],
        "building_type_scope": [
            "apartment",
            "new_apartment"
        ],
        "functionality": "feature",
        "type": "boolean",
        "unit": "",
        "location": "features",
        "name": "includes_ground_floor",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "flood_general"
        ]
    },
    {
        "modules": [
            "core",
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment",
            "construction_plot"
        ],
        "functionality": "feature",
        "type": "enum",
        "unit": "",
        "location": "features",
        "name": "f_flood_risk",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": [
            "high",
            "medium",
            "low",
            "unknown",
            "none"
        ],
        "sections": [
            "flood",
            "flood_simple",
            "flood_regular"
        ]
    },
    {
        "modules": [
            "core",
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment"
        ],
        "functionality": "feature",
        "type": "float",
        "unit": "m",
        "location": "features",
        "name": "f_dist_building_flood",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "flood",
            "flood_simple",
            "flood_regular"
        ]
    },
    {
        "modules": [
            "core",
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment",
            "construction_plot"
        ],
        "functionality": "feature",
        "type": "enum",
        "unit": "",
        "location": "features",
        "name": "f_parcel_flood_risk",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": [
            "high",
            "medium",
            "low",
            "unknown",
            "none"
        ],
        "sections": [
            "flood",
            "flood_simple",
            "flood_regular"
        ]
    },
    {
        "modules": [
            "core",
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment",
            "construction_plot"
        ],
        "functionality": "feature",
        "type": "float",
        "unit": "m",
        "location": "features",
        "name": "f_dist_parcel_flood",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "flood",
            "flood_simple",
            "flood_regular"
        ]
    },
    {
        "modules": [
            "core",
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment",
            "construction_plot"
        ],
        "functionality": "feature",
        "type": "enum",
        "unit": "",
        "location": "features",
        "name": "f_flood_risk_insurance_exclusion",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": [
            "high",
            "none"
        ],
        "sections": []
    },
    {
        "modules": [
            "core",
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment",
            "construction_plot"
        ],
        "functionality": "feature",
        "type": "enum",
        "unit": "",
        "location": "features",
        "name": "f_flood_risk_insurance_exclusion_1m",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": [
            "high",
            "none"
        ],
        "sections": [
            "flood",
            "flood_insurance_exclusion"
        ]
    },
    {
        "modules": [
            "core",
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment"
        ],
        "functionality": "feature",
        "type": "float",
        "unit": "m",
        "location": "features",
        "name": "f_dist_building_flood_insurance_exclusion",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "flood",
            "flood_insurance_exclusion"
        ]
    },
    {
        "modules": [
            "core",
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment",
            "construction_plot"
        ],
        "functionality": "feature",
        "type": "enum",
        "unit": "",
        "location": "features",
        "name": "f_parcel_flood_risk_insurance_exclusion",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": [
            "high",
            "none"
        ],
        "sections": []
    },
    {
        "modules": [
            "core",
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment",
            "construction_plot"
        ],
        "functionality": "feature",
        "type": "enum",
        "unit": "",
        "location": "features",
        "name": "f_parcel_flood_risk_insurance_exclusion_1m",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": [
            "high",
            "none"
        ],
        "sections": [
            "flood",
            "flood_insurance_exclusion"
        ]
    },
    {
        "modules": [
            "core",
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment",
            "construction_plot"
        ],
        "functionality": "feature",
        "type": "float",
        "unit": "m",
        "location": "features",
        "name": "f_dist_parcel_flood_insurance_exclusion",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "flood",
            "flood_insurance_exclusion"
        ]
    },
    {
        "modules": [
            "core",
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment",
            "construction_plot"
        ],
        "functionality": "feature",
        "type": "enum",
        "unit": "",
        "location": "features",
        "name": "f_flood_risk_composite",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": [
            "high",
            "medium",
            "low",
            "none"
        ],
        "sections": [
            "flood"
        ]
    },
    {
        "modules": [
            "core"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment"
        ],
        "functionality": "feature",
        "type": "enum",
        "unit": "",
        "location": "features",
        "name": "f_flood_risk_max_parcel",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": [
            "high",
            "medium",
            "low",
            "none"
        ],
        "sections": [
            "flood"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment",
            "construction_plot"
        ],
        "functionality": "feature",
        "type": "enum",
        "unit": "",
        "location": "dvm_features",
        "name": "surroundings",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": [
            "urban",
            "rural",
            "isolated"
        ],
        "sections": [
            "surroundings"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment",
            "construction_plot"
        ],
        "functionality": "feature",
        "type": "multi_enum_open",
        "unit": "",
        "location": "dvm_features",
        "name": "other_positive",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": [
            "classified_facade",
            "green_neighbourhood",
            "sea_view",
            "no_direct_traffic",
            "exceptional_view",
            "other"
        ],
        "sections": [
            "surroundings"
        ]
    },
    {
        "modules": [],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment",
            "construction_plot"
        ],
        "functionality": "feature",
        "type": "string",
        "unit": "",
        "location": "dvm_features",
        "name": "other_positive_details",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "surroundings"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment",
            "construction_plot"
        ],
        "functionality": "feature",
        "type": "multi_enum_open",
        "unit": "",
        "location": "dvm_features",
        "name": "other_negative",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": [
            "tram",
            "train",
            "metro",
            "traffic",
            "busy_area",
            "other"
        ],
        "sections": [
            "surroundings"
        ]
    },
    {
        "modules": [],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment",
            "construction_plot"
        ],
        "functionality": "feature",
        "type": "string",
        "unit": "",
        "location": "dvm_features",
        "name": "other_negative_details",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "surroundings"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment",
            "construction_plot"
        ],
        "functionality": "feature",
        "type": "multiline-text",
        "unit": "",
        "location": "dvm_features",
        "name": "remarks",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment",
            "construction_plot"
        ],
        "functionality": "feature",
        "type": "multiline-text",
        "unit": "",
        "location": "dvm_features",
        "name": "internal_remarks",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment"
        ],
        "functionality": "feature",
        "type": "enum",
        "unit": "",
        "location": "features",
        "name": "epc_source",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": [
            "user_estimate",
            "certificate"
        ],
        "sections": [
            "building"
        ]
    },
    {
        "modules": [
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment"
        ],
        "functionality": "text",
        "type": "string",
        "unit": "",
        "location": "section",
        "name": "room",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment"
        ],
        "functionality": "feature",
        "type": "enum_open",
        "unit": "",
        "location": "section_data",
        "name": "room.type",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": [
            "bedroom",
            "bathroom",
            "toilet",
            "kitchen",
            "living_room",
            "laundry_room",
            "lounge",
            "cellar",
            "attic",
            "hallway",
            "patio",
            "entry_hall"
        ],
        "sections": [
            "room"
        ]
    },
    {
        "modules": [
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment"
        ],
        "functionality": "feature",
        "type": "float",
        "unit": "m2",
        "location": "section_data",
        "name": "room.living_area",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "room"
        ]
    },
    {
        "modules": [
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment"
        ],
        "functionality": "feature",
        "type": "string",
        "unit": "",
        "location": "section_data",
        "name": "room.comment",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "room"
        ]
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "",
        "unit": "",
        "location": "",
        "name": "",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment",
            "construction_plot"
        ],
        "functionality": "text",
        "type": "string",
        "unit": "",
        "location": "section",
        "name": "valuations",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment",
            "construction_plot"
        ],
        "functionality": "feature",
        "type": "float",
        "unit": "euro",
        "location": "valuation",
        "name": "market_value",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "valuations"
        ]
    },
    {
        "modules": [
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment"
        ],
        "functionality": "feature",
        "type": "float",
        "unit": "euro",
        "location": "valuation",
        "name": "market_value_post_renovation",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "valuations"
        ]
    },
    {
        "modules": [
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "new_house",
            "new_apartment"
        ],
        "functionality": "feature",
        "type": "float",
        "unit": "euro",
        "location": "valuation",
        "name": "construction_cost",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "valuations"
        ]
    },
    {
        "modules": [
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "new_house",
            "new_apartment"
        ],
        "functionality": "feature",
        "type": "float",
        "unit": "euro",
        "location": "valuation",
        "name": "market_value_pre_construction",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "valuations"
        ]
    },
    {
        "modules": [
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment",
            "construction_plot"
        ],
        "functionality": "feature",
        "type": "float",
        "unit": "euro",
        "location": "valuation",
        "name": "forced_sale_value_pre_construction",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "valuations"
        ]
    },
    {
        "modules": [
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment",
            "construction_plot"
        ],
        "functionality": "feature",
        "type": "float",
        "unit": "euro",
        "location": "valuation",
        "name": "forced_sale_value_post_renovation",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "valuations"
        ]
    },
    {
        "modules": [
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment",
            "construction_plot"
        ],
        "functionality": "feature",
        "type": "float",
        "unit": "euro",
        "location": "valuation",
        "name": "forced_sale_value",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "valuations"
        ]
    },
    {
        "modules": [
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment"
        ],
        "functionality": "feature",
        "type": "float",
        "unit": "euro/month",
        "location": "valuation",
        "name": "rental_value",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "valuations"
        ]
    },
    {
        "modules": [
            "core",
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment"
        ],
        "functionality": "feature",
        "type": "float",
        "unit": "euro",
        "location": "valuation",
        "name": "reconstruction_value",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "valuations",
            "reconstruction_value"
        ]
    },
    {
        "modules": [
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment"
        ],
        "functionality": "feature",
        "type": "float",
        "unit": "euro",
        "location": "valuation",
        "name": "cadastral_income",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "valuations"
        ]
    },
    {
        "modules": [
            "core",
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment",
            "construction_plot"
        ],
        "functionality": "feature",
        "type": "float",
        "unit": "",
        "location": "valuation",
        "name": "index_value",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "valuations",
            "reconstruction_value"
        ]
    },
    {
        "modules": [
            "core",
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment",
            "construction_plot"
        ],
        "functionality": "feature",
        "type": "enum",
        "unit": "",
        "location": "valuation",
        "name": "index_type",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": [
            "abex"
        ],
        "sections": [
            "valuations",
            "reconstruction_value"
        ]
    },
    {
        "modules": [
            "core",
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment"
        ],
        "functionality": "feature",
        "type": "string",
        "unit": "",
        "location": "features",
        "name": "level",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "reconstruction_value",
            "flood_general"
        ]
    },
    {
        "modules": [
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment"
        ],
        "functionality": "text",
        "type": "string",
        "unit": "",
        "location": "section",
        "name": "reference_properties_location",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment",
            "construction_plot"
        ],
        "functionality": "section",
        "type": "string",
        "unit": "",
        "location": "section",
        "name": "reference_properties_characteristics",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [
            "core"
        ],
        "building_type_scope": [
            "house",
            "apartment"
        ],
        "functionality": "section",
        "type": "string",
        "unit": "",
        "location": "section",
        "name": "flood",
        "search_dict_name": "",
        "icon": "ic-flood",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [
            "core"
        ],
        "building_type_scope": [
            "house",
            "apartment"
        ],
        "functionality": "section",
        "type": "string",
        "unit": "",
        "location": "section",
        "name": "energy",
        "search_dict_name": "",
        "icon": "ic-environment",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment",
            "construction_plot"
        ],
        "functionality": "section",
        "type": "string",
        "unit": "",
        "location": "section",
        "name": "flood_simple",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [
            "core",
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment",
            "construction_plot"
        ],
        "functionality": "feature",
        "type": "float",
        "unit": "m",
        "location": "features",
        "name": "f_ground_height_above_sea",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "flood",
            "flood_simple"
        ]
    },
    {
        "modules": [
            "core",
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment",
            "construction_plot"
        ],
        "functionality": "section",
        "type": "string",
        "unit": "",
        "location": "section",
        "name": "faq",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "new_house",
            "new_apartment"
        ],
        "functionality": "feature",
        "type": "",
        "unit": "",
        "location": "features",
        "name": "no_building_picture",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "construction_plot"
        ],
        "functionality": "feature",
        "type": "",
        "unit": "",
        "location": "features",
        "name": "no_parcel_picture",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "new_house"
        ],
        "functionality": "feature",
        "type": "",
        "unit": "",
        "location": "features",
        "name": "no_garden_technical_drawing",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "new_house",
            "new_apartment"
        ],
        "functionality": "feature",
        "type": "",
        "unit": "",
        "location": "features",
        "name": "parcel_selection",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "new_house",
            "new_apartment"
        ],
        "functionality": "feature",
        "type": "",
        "unit": "",
        "location": "features",
        "name": "vat",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment"
        ],
        "functionality": "section",
        "type": "string",
        "unit": "",
        "location": "section",
        "name": "questions_feedback",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [
            "core",
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment"
        ],
        "functionality": "section",
        "type": "string",
        "unit": "",
        "location": "section",
        "name": "contact_info",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [
            "avm",
            "dvm",
            "ovm"
        ],
        "building_type_scope": [
            "house",
            "apartment"
        ],
        "functionality": "section",
        "type": "string",
        "unit": "",
        "location": "section",
        "name": "disclaimer",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [
            "dvm"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "what-is-avm",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "general"
        ]
    },
    {
        "modules": [
            "dvm"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "what-is-dvm",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "general"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm",
            "borrower"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "what-is-ovm",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "general"
        ]
    },
    {
        "modules": [
            "borrower"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "why-ovm",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "general"
        ]
    },
    {
        "modules": [
            "dvm",
            "ers",
            "ovm"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "what-is-dashboard",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "general"
        ]
    },
    {
        "modules": [
            "dvm",
            "ers",
            "ovm"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "what-is-draft",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "general"
        ]
    },
    {
        "modules": [
            "ovm",
            "borrower"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "when-report",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "report"
        ]
    },
    {
        "modules": [
            "borrower"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "how-download-report",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "report"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "market-price-incorrect",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "report"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "issue-parcels",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "report"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "old-picture-or-3d",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "report"
        ]
    },
    {
        "modules": [
            "dvm",
            "ers",
            "ovm"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "report-language",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "report"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "wrong-pictures",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "report"
        ]
    },
    {
        "modules": [
            "dvm",
            "ers"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "sharing",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "report"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "remarks-language",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "report"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "ING-how-ovm-request",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "application"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "where-customer-ref",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "application"
        ]
    },
    {
        "modules": [
            "dvm"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "building-type-apartment-building",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "application"
        ]
    },
    {
        "modules": [
            "dvm",
            "ers",
            "ovm"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "address-not-found",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "application"
        ]
    },
    {
        "modules": [
            "dvm",
            "ers",
            "ovm"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "find-epc",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "application"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "epc-label-vs-number",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "application"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "find-living-area",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "application"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "find-box-number",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "application"
        ]
    },
    {
        "modules": [
            "dvm",
            "ers",
            "ovm"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "find-construction-year",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "application"
        ]
    },
    {
        "modules": [
            "dvm",
            "ers",
            "ovm"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "construction-vs-renovation",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "application"
        ]
    },
    {
        "modules": [
            "dvm"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "over-two-days",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "application"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "carport",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "application"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "personal-use",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "application"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "is-request-submitted",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "dashboard"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "review",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "dashboard"
        ]
    },
    {
        "modules": [
            "dvm",
            "ers",
            "ovm"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "find-previous-request",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "dashboard"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "download-report",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "dashboard"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "language-impact",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "dashboard"
        ]
    },
    {
        "modules": [
            "dvm",
            "ers",
            "ovm"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "unsubmission-window",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "dashboard"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "after-sixty-minutes",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "dashboard"
        ]
    },
    {
        "modules": [
            "dvm",
            "ovm"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "review-billing",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "dashboard"
        ]
    },
    {
        "modules": [
            "ers"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "what-is-ers",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "general"
        ]
    },
    {
        "modules": [
            "ers"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "what-is-common-wall",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "general"
        ]
    },
    {
        "modules": [
            "ers"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "what-is-protected-volume",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "general"
        ]
    },
    {
        "modules": [
            "ers"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "what-is-living-area",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "general"
        ]
    },
    {
        "modules": [
            "ers"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "apartment-ers",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "application"
        ]
    },
    {
        "modules": [
            "ers "
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "no-3d-model-ers-quality",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "results"
        ]
    },
    {
        "modules": [
            "ers"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "what-is-renovation-goal",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "application"
        ]
    },
    {
        "modules": [
            "ers"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "missing-renovation-option",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "application"
        ]
    },
    {
        "modules": [
            "ers"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "choosing-insulation-material",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "application"
        ]
    },
    {
        "modules": [
            "ers"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "own-property-ers",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "application"
        ]
    },
    {
        "modules": [
            "ers"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "estimated-epc-different-than-actual-epc",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "results"
        ]
    },
    {
        "modules": [
            "ers"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "how-is-vat-computed",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "results"
        ]
    },
    {
        "modules": [
            "ers"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "price-ers-includes",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "results"
        ]
    },
    {
        "modules": [
            "ers"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "incorrect-region",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "results"
        ]
    },
    {
        "modules": [
            "ers"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "interaction-between-renovations",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "results"
        ]
    },
    {
        "modules": [
            "ers"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "ers-reliability",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "report"
        ]
    },
    {
        "modules": [
            "ers"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "report-measurements-dont-match",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "report"
        ]
    },
    {
        "modules": [
            "dvm"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "how-to-create-val-req",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "application"
        ]
    },
    {
        "modules": [
            "dvm",
            "ers"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "how-to-access-app",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "general"
        ]
    },
    {
        "modules": [
            "dvm"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "how-to-use-dashboard",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "application"
        ]
    },
    {
        "modules": [
            "dvm"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "what-is-val-report",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "report"
        ]
    },
    {
        "modules": [
            "dvm"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "how-to-create-req-const",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "application"
        ]
    },
    {
        "modules": [
            "dvm"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "how-to-create-req-new-house",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "application"
        ]
    },
    {
        "modules": [
            "dvm"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "how-to-create-req-existing-house",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "application"
        ]
    },
    {
        "modules": [
            "dvm"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "how-to-create-req-new-qpqrtment",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "application"
        ]
    },
    {
        "modules": [
            "dvm"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "how-to-create-req-existing-apartment",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "application"
        ]
    },
    {
        "modules": [
            "dvm"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "how-to-confirm-address-existing-prop",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "application"
        ]
    },
    {
        "modules": [
            "dvm"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "how-to-confirm-address-prop-under-const",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "application"
        ]
    },
    {
        "modules": [
            "ers"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "how-to-create-ers-req-3D",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "application"
        ]
    },
    {
        "modules": [
            "ers"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "how-to-create-ers-req-2D",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "application"
        ]
    },
    {
        "modules": [
            "ers"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "how-to-create-ers-req-building-not-found",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "application"
        ]
    },
    {
        "modules": [
            "ers"
        ],
        "building_type_scope": [],
        "functionality": "faq",
        "type": "",
        "unit": "",
        "location": "faq",
        "name": "how-total-investment-cost-calculated",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "application"
        ]
    },
    {
        "modules": [
            "ers"
        ],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "enum",
        "unit": "",
        "location": "features",
        "name": "property_type",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": [
            "house",
            "appartment"
        ],
        "sections": [
            "property_type"
        ]
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "boolean",
        "unit": "",
        "location": "features_question",
        "name": "insulated_floors",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "insulation"
        ]
    },
    {
        "modules": [
            "ers"
        ],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "boolean",
        "unit": "",
        "location": "features",
        "name": "insulated_floors",
        "search_dict_name": "floor_attributes.0.has_insulation",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "insulation"
        ]
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "boolean",
        "unit": "",
        "location": "features_question",
        "name": "insulated_roof",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "insulation"
        ]
    },
    {
        "modules": [
            "ers"
        ],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "boolean",
        "unit": "",
        "location": "features",
        "name": "insulated_roof",
        "search_dict_name": "roof_attributes.0.has_insulation",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "insulation"
        ]
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "boolean",
        "unit": "",
        "location": "features_question",
        "name": "insulated_walls",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "insulation"
        ]
    },
    {
        "modules": [
            "ers"
        ],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "boolean",
        "unit": "",
        "location": "features",
        "name": "insulated_walls",
        "search_dict_name": "wall_attributes.0.has_insulation",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "insulation"
        ]
    },
    {
        "modules": [
            "ers"
        ],
        "building_type_scope": [
            "house",
            "apartment",
            "new_house",
            "new_apartment"
        ],
        "functionality": "feature",
        "type": "multi_enum_open",
        "unit": "",
        "location": "features",
        "name": "window_type",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": [
            "single",
            "ordinary_double",
            "hr_double_before_2000",
            "hr_double_after_2000",
            "triple"
        ],
        "sections": [
            "insulation",
            "building_structure"
        ]
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "boolean",
        "unit": "",
        "location": "features_question",
        "name": "has_attic",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [
            "ers"
        ],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "boolean",
        "unit": "",
        "location": "features",
        "name": "has_attic",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "insulation"
        ]
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "integer",
        "unit": "",
        "location": "features_question",
        "name": "insulation_floors_when",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [
            "ers"
        ],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "integer",
        "unit": "",
        "location": "features",
        "name": "floors_insulation_years",
        "search_dict_name": "floor_attributes.0.insulation_year",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "insulation"
        ]
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "integer",
        "unit": "",
        "location": "features_question",
        "name": "insulation_roof_when",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [
            "ers"
        ],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "integer",
        "unit": "",
        "location": "features",
        "name": "roof_insulation_year",
        "search_dict_name": "roof_attributes.0.insulation_year",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "insulation"
        ]
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "integer",
        "unit": "",
        "location": "features_question",
        "name": "insulation_walls_when",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [
            "ers"
        ],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "integer",
        "unit": "",
        "location": "features",
        "name": "walls_insulation_year",
        "search_dict_name": "wall_attributes.0.insulation_year",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "insulation"
        ]
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "boolean",
        "unit": "",
        "location": "features_question",
        "name": "has_basement",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [
            "ers"
        ],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "boolean",
        "unit": "",
        "location": "features",
        "name": "has_basement",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "insulation"
        ]
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "boolean",
        "unit": "",
        "location": "features_question",
        "name": "is_attic_heated",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [
            "ers"
        ],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "boolean",
        "unit": "",
        "location": "features",
        "name": "is_attic_heated",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "insulation"
        ]
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "string",
        "unit": "",
        "location": "address",
        "name": "streetname",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "address"
        ]
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "string",
        "unit": "",
        "location": "address",
        "name": "streetnumber",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "address"
        ]
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "string",
        "unit": "",
        "location": "address",
        "name": "boxnumber",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "address"
        ]
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "string",
        "unit": "",
        "location": "address",
        "name": "postalcode",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "address"
        ]
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "string",
        "unit": "",
        "location": "address",
        "name": "municipality",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "address"
        ]
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "integer",
        "unit": "",
        "location": "features_question",
        "name": "epc_known",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [
            "ers"
        ],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "integer",
        "unit": "",
        "location": "features",
        "name": "epc_score",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "house_info"
        ]
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "text",
        "type": "string",
        "unit": "",
        "location": "section",
        "name": "heating",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [
            "ers"
        ],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "enum",
        "unit": "",
        "location": "features",
        "name": "heating_fuel",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": [
            "gas",
            "oil",
            "wood",
            "pellets",
            "coal",
            "district_heating",
            "electricity"
        ],
        "sections": [
            "consumption"
        ]
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "enum",
        "unit": "",
        "location": "features_question",
        "name": "select_heating_fuel",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": [
            "condensing_boiler",
            "standard_boiler_open",
            "standard_boiler_closed",
            "heat_pump",
            "standard_boiler",
            "chp",
            "energy_efficient",
            "non_energy_efficient",
            "electric_heeater"
        ],
        "sections": []
    },
    {
        "modules": [
            "ers"
        ],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "enum",
        "unit": "",
        "location": "features",
        "name": "heating_type",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": [
            "boiler:condensing",
            "boiler:standard",
            "heat_pump",
            "stove",
            "electric_heater",
            "district_heating"
        ],
        "sections": [
            "consumption"
        ]
    },
    {
        "modules": [
            "ers"
        ],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "integer",
        "unit": "",
        "location": "features",
        "name": "heating_installation_year",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "consumption"
        ]
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "text",
        "type": "string",
        "unit": "",
        "location": "section",
        "name": "ventilation",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [
            "ers"
        ],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "enum",
        "unit": "",
        "location": "features",
        "name": "ventilation_type",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": [
            "natural",
            "mechanical"
        ],
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "enum",
        "unit": "",
        "location": "features_question",
        "name": "ventilation_type",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": [
            "natural",
            "mechanical"
        ],
        "sections": []
    },
    {
        "modules": [
            "ers"
        ],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "enum",
        "unit": "",
        "location": "features_question",
        "name": "ventilation_subtype",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": [
            "system_b",
            "system_c",
            "system_cplus",
            "system_d"
        ],
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "text",
        "type": "string",
        "unit": "",
        "location": "section",
        "name": "renewables",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": [
            "solar_boiler",
            "solar_panels",
            "battery",
            "air_conditioner"
        ],
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "boolean",
        "unit": "",
        "location": "features",
        "name": "solar_boiler",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "consumption"
        ]
    },
    {
        "modules": [
            "ers"
        ],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "boolean",
        "unit": "",
        "location": "features",
        "name": "solar_panels",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "consumption"
        ]
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "boolean",
        "unit": "",
        "location": "features",
        "name": "battery",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "consumption"
        ]
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "boolean",
        "unit": "",
        "location": "features",
        "name": "air_conditioner",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "consumption"
        ]
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "text",
        "type": "string",
        "unit": "",
        "location": "section",
        "name": "additional_consumption",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "boolean",
        "unit": "",
        "location": "features",
        "name": "spa_room",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "boolean",
        "unit": "",
        "location": "features",
        "name": "swimming_pool",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [
            "ers"
        ],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "boolean",
        "unit": "",
        "location": "features",
        "name": "electric_vehicle",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "text",
        "type": "string",
        "unit": "",
        "location": "section",
        "name": "consumers",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [
            "ers"
        ],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "integer",
        "unit": "",
        "location": "features",
        "name": "n_residents",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "consumption"
        ]
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "boolean",
        "unit": "",
        "location": "features_question",
        "name": "protected_consumer",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "boolean",
        "unit": "",
        "location": "features",
        "name": "protected_consumer",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "integer",
        "unit": "",
        "location": "features_question",
        "name": "installation_year",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "integer",
        "unit": "",
        "location": "features_question",
        "name": "heater",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "text",
        "type": "string",
        "unit": "",
        "location": "section",
        "name": "energy_consumption_info",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "text",
        "type": "string",
        "unit": "",
        "location": "section",
        "name": "house_info",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "text",
        "type": "string",
        "unit": "",
        "location": "section",
        "name": "consumption",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "text",
        "type": "string",
        "unit": "",
        "location": "section",
        "name": "property_type",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "text",
        "type": "string",
        "unit": "",
        "location": "section",
        "name": "address",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "text",
        "type": "string",
        "unit": "",
        "location": "section",
        "name": "insulation",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "text",
        "type": "string",
        "unit": "",
        "location": "section",
        "name": "house_information",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "integer",
        "unit": "",
        "location": "features_question",
        "name": "consumption_known",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "integer",
        "unit": "",
        "location": "features_question",
        "name": "yearly_electricity_consumption",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "string",
        "unit": "",
        "location": "features_question",
        "name": "yearly_electricity_consumption_subtitle",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [
            "ers"
        ],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "integer",
        "unit": "",
        "location": "features",
        "name": "yearly_electricity_consumption",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": [
            "consumption"
        ]
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "integer",
        "unit": "",
        "location": "features_question",
        "name": "yearly_fuel_consumption",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "integer",
        "unit": "",
        "location": "features_question",
        "name": "yearly_heating_consumption",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "integer",
        "unit": "",
        "location": "features_question",
        "name": "f_construction_year",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "integer",
        "unit": "",
        "location": "features_question",
        "name": "f_living_area",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "string",
        "unit": "",
        "location": "features_question",
        "name": "approx_living_area",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "boolean",
        "unit": "",
        "location": "features_question",
        "name": "solar_panels",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "integer",
        "unit": "",
        "location": "features_question",
        "name": "insulated_windows",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "boolean",
        "unit": "",
        "location": "features_question",
        "name": "insulated_single_renovated",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "boolean",
        "unit": "",
        "location": "features_question",
        "name": "insulated_plural_renovated",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [
            "ers"
        ],
        "building_type_scope": [],
        "functionality": "feature",
        "type": "integer",
        "unit": "",
        "location": "features",
        "name": "gas_consumption_approx",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "question",
        "type": "string",
        "unit": "",
        "location": "energy_quizz",
        "name": "q1.q",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "wrong_answer",
        "type": "string",
        "unit": "",
        "location": "energy_quizz",
        "name": "q1.a1",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "correct_answer",
        "type": "string",
        "unit": "",
        "location": "energy_quizz",
        "name": "q1.a2",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "wrong_answer",
        "type": "string",
        "unit": "",
        "location": "energy_quizz",
        "name": "q1.a3",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "question",
        "type": "string",
        "unit": "",
        "location": "energy_quizz",
        "name": "q2.q",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "wrong_answer",
        "type": "string",
        "unit": "",
        "location": "energy_quizz",
        "name": "q2.a1",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "correct_answer",
        "type": "string",
        "unit": "",
        "location": "energy_quizz",
        "name": "q2.a2",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "wrong_answer",
        "type": "string",
        "unit": "",
        "location": "energy_quizz",
        "name": "q2.a3",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "wrong_answer",
        "type": "string",
        "unit": "",
        "location": "energy_quizz",
        "name": "q2.a4",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "question",
        "type": "string",
        "unit": "",
        "location": "energy_quizz",
        "name": "q3.q",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "wrong_answer",
        "type": "string",
        "unit": "",
        "location": "energy_quizz",
        "name": "q3.a1",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "wrong_answer",
        "type": "string",
        "unit": "",
        "location": "energy_quizz",
        "name": "q3.a2",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "correct_answer",
        "type": "string",
        "unit": "",
        "location": "energy_quizz",
        "name": "q3.a3",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "question",
        "type": "string",
        "unit": "",
        "location": "energy_quizz",
        "name": "q4.q",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "wrong_answer",
        "type": "string",
        "unit": "",
        "location": "energy_quizz",
        "name": "q4.a1",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "wrong_answer",
        "type": "string",
        "unit": "",
        "location": "energy_quizz",
        "name": "q4.a2",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "correct_answer",
        "type": "string",
        "unit": "",
        "location": "energy_quizz",
        "name": "q4.a3",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "question",
        "type": "string",
        "unit": "",
        "location": "energy_quizz",
        "name": "q5.q",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "wrong_answer",
        "type": "string",
        "unit": "",
        "location": "energy_quizz",
        "name": "q5.a1",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "correct_answer",
        "type": "string",
        "unit": "",
        "location": "energy_quizz",
        "name": "q5.a2",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "wrong_answer",
        "type": "string",
        "unit": "",
        "location": "energy_quizz",
        "name": "q5.a3",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "question",
        "type": "string",
        "unit": "",
        "location": "energy_quizz",
        "name": "q6.q",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "correct_answer",
        "type": "string",
        "unit": "",
        "location": "energy_quizz",
        "name": "q6.a1",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "wrong_answer",
        "type": "string",
        "unit": "",
        "location": "energy_quizz",
        "name": "q6.a2",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    },
    {
        "modules": [],
        "building_type_scope": [],
        "functionality": "wrong_answer",
        "type": "string",
        "unit": "",
        "location": "energy_quizz",
        "name": "q6.a3",
        "search_dict_name": "",
        "icon": "",
        "fallback": "",
        "values": null,
        "sections": []
    }
]