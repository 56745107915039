<template>
    <re-page>
        <re-banner :title="$t('global.commons.redirection_notice')" />
        <re-loading wrapped />
    </re-page>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
window.location.replace(t('full_disclaimer.url'))
</script>
