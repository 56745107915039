<template>
    <re-select
        v-model="selectedRoles"
        filled
        multiple
        hide-dropdown-icon
        :option-disable="(item) => (props.unremovable.includes(item) ? true : item.cannotSelect)"
        use-input
        use-chips
        stack-label
        :readonly="!props.editable"
        :options="options"
        class="admin-role-select"
        bg-color="light"
        @filter="filterOptions"
        @popup-hide="isOpen = false"
    />
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import enums from '@/shared/plugins/enums'

const staticRoles = enums.roles
const options = ref(staticRoles)

const props = defineProps({
    roles: { type: Array, required: true },
    editable: { type: Boolean, default: false },
    unremovable: { type: Array, default: () => [] },
})

const emit = defineEmits(['select-role'])
const selectedRoles = ref(props.roles)
const unselectedRoles = computed(() => {
    return staticRoles.filter((r) => !selectedRoles.value.includes(r))
})
const filterOptions = (val, update) => {
    if (val === '') {
        update(() => {
            options.value = staticRoles.filter((r) => !selectedRoles.value.includes(r))
        })
        return
    }

    update(() => {
        const needle = val.toLowerCase()
        options.value = unselectedRoles.value.filter((v) => v.toLowerCase().indexOf(needle) > -1)
    })
}

watch(selectedRoles, (roles) => {
    options.value = options.value.filter((r) => !roles.includes(r))
    emit('select-role', roles)
})
</script>

<style lang="scss" scoped>
.admin-role-select {
    max-width: 15rem;
}
</style>
