<template>
    <div
        :class="[props.xsFullWidth && 'col-xs-12 col-sm-auto', props.stretch && 'flex-stretch', props.class]"
    >
        <q-btn
            no-caps
            :no-wrap="props.noWrap"
            unelevated
            :class="btnClasses"
            :label="props.label"
            :flat="props.flat"
            v-bind="{ ...$attrs, ...stylingAttrs }"
        >
            <slot></slot>
            <template v-if="!props.label && loadingLabel" #default>
                <!-- Quasar sets the button width based on the default slot, 
             irrespective of the content of the loading slot, even in loading state.
             For that reason, when there is no label but there is a loadingLabel,
             we copy the content of the loading slot in the default slot just to set the right width. -->
                <span class="flex no-wrap">
                    <q-spinner-facebook class="on-left" />
                    <span>{{ loadingLabel }}</span>
                </span>
            </template>
            <template #loading>
                <span class="flex no-wrap">
                    <q-spinner-facebook v-if="loadingIconSide === 'left'" class="on-left" />
                    <span>{{ loadingLabel }}</span>
                    <q-spinner-facebook v-if="loadingIconSide === 'right'" class="on-right" />
                </span>
            </template>
        </q-btn>
    </div>
</template>
<script setup>
import { computed } from 'vue'
import { colors } from 'quasar'

// This is needed to prevent Quasar from adding the $attrs to the root div instead of the q-btn:
defineOptions({ inheritAttrs: false })

const props = defineProps({
    noWrap: {
        type: Boolean,
        default: true,
    },
    color: {
        type: String,
        default: undefined,
    },
    textColor: {
        type: String,
        default: undefined,
    },
    noMinWidth: {
        type: Boolean,
        default: false,
    },
    // isClear: {
    //     type: Boolean,
    //     default: false,
    // },
    loadingLabel: {
        type: String,
        default: '',
    },
    loadingIconSide: {
        type: String,
        default: 'left',
        validator: (v) => ['left', 'right'].includes(v),
    },
    label: {
        type: [String, Number],
        default: undefined,
    },
    size: {
        type: String,
        default: 'lg',
        validator: (v) => ['xs', 'sm', 'md', 'lg', 'xl'].includes(v),
    },
    flat: {
        type: Boolean,
        default: false,
    },
    xsFullWidth: {
        type: Boolean,
        default: false,
    },
    fullWidth: {
        type: Boolean,
        default: false,
    },
    stretch: {
        type: Boolean,
        default: false,
    },
    class: {
        type: [String, Array, Object],
        default: null,
    },
    btnClasses: {
        type: String,
        default: null,
    },
})

const stylingAttrs = computed(() => {
    if (!props.color) {
        return {
            outline: true,
            color: 'white',
            class: props.flat ? '' : 'bg-white re-default-border',
            textColor: props.textColor || 'accent',
        }
    }

    if (props.color === 'main') {
        return {
            filled: true,
            color: 'accent',
            textColor: props.textColor || 'white',
        }
    }

    let textColor = props.textColor
    // Determine text color based on background color brightness
    if (!textColor) {
        if (colors.brightness(colors.getPaletteColor(props.color)) > 155) textColor = 'dark'
        else textColor = 'white'
    }
    return { outline: false, filled: true, color: props.color, textColor: textColor }
})

const btnClasses = computed(() => {
    const classes = ['self-center']
    // if (props.isClear) classes.push('re-btn-clear')
    if (!props.noMinWidth && props.label) classes.push('re-btn-min-width')
    if (props.size) classes.push(`re-${props.size}`)
    if (props.xsFullWidth) classes.push('xs-full-width')
    if (props.fullWidth || props.stretch) classes.push('full-width')

    return [...classes, props.btnClasses]
})
</script>
<style lang="scss" scoped>
// NOTE: we set line-height here instead of $button-line-height to preserve
// default button icon appearance:
.q-btn {
    line-height: 1em;
    :deep(.q-icon),
    :deep(.q-spinner) {
        font-size: 1.3em;
    }

    &--flat.disabled,
    &--flat[disabled] {
        background-color: var(--secondary-bground) !important;
        color: var(--secondary-light) !important;
    }

    &.re-default-border.q-btn--outline:before {
        border-color: $border;
    }
}

body.screen--xs .q-btn.xs-full-width {
    width: 100%;
}

a.q-btn:hover {
    text-decoration: none !important;
}

.q-btn.re-btn-min-width {
    min-width: 6em;
}
</style>
