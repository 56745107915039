<template>
    <re-page :background-style="backgroundStyle" show-background is-centered>
        <re-title>{{ $t('monolith.apps.welcome') }}{{ user ? ` ${user}` : '' }}</re-title>
        <div class="row q-col-gutter-lg">
            <div v-for="app in curUser.availableApps" :key="app" class="col-xs-12 col-sm-6 col-md-4 col-xl-3">
                <app-card :app-id="app" />
            </div>
        </div>
    </re-page>
</template>
<script setup>
import utils from '@/shared/plugins/utils'
import AppCard from './AppCard.vue'
import { inject, computed } from 'vue'

const curUser = inject('curUser')
const user = computed(() => (curUser.getUsername ? utils.user_name(curUser.getUsername).split(' ')[0] : null))
const backgroundUrl = new URL('@/assets/img/city.jpg', import.meta.url).pathname
const backgroundStyle = {
    background: `linear-gradient(180deg, rgba(249, 250, 252, 0) 0px, var(--light) 150px), url('${backgroundUrl}') no-repeat, rgba(211, 211, 211, 0.242) 0px -250px / 100% 356.25% no-repeat`,
    backgroundSize: 'contain',
    backgroundPosition: 'inherit',
}
</script>
