import { inject } from 'vue'
import utils from '@/shared/plugins/utils'
import axios from '@/shared/plugins/axios'

import { clear } from 'idb-keyval'
import { queryClient } from '@/composables/query'

// const isDev = import.meta.env.MODE.toLowerCase() === 'development'

// TODO: should we add this to user.js, or rather avoid assumptions in role-checking
// const defaultRoles = () => ({
//     '*': [],
//     AVM: [],
//     DVM: [],
//     ERS: [],
//     OVM: [],
// })

const RE_USERNAME = 're-username'

export const Mutations = {
    SET_AUTHENTICATED: 'μ: Mark user as authenticated',
    SET_USERNAME: 'μ: Store username in state and localstorage',
    RESET_USERNAME: 'μ: Remove username from state and localstorage',
}

export const Actions = {
    CHECK_AUTHENTICATION: '🔐 Check if user is authenticated',
    CHECK_OFFLINE_AUTHENTICATION: '🔐 Check if user was authenticated when offline',
    STORE_AUTH_USER: '🔐 Store copy of Auth user composable',
    LOGOUT: '🔐 Reset user data',
}

const auth = {
    namespaced: false,
    state: {
        authenticated: false,
        username: undefined,
        curUser: undefined,
    },
    getters: {
        isAuthenticated(state) {
            return state.authenticated
        },
        getUsername(state) {
            return state.username
        },
        getCurUser(state) {
            return state.curUser
        },
    },
    mutations: {
        [Mutations.SET_AUTHENTICATED](state, data) {
            state.authenticated = !!data
        },
        [Mutations.SET_USERNAME](state, data) {
            state.username = data
            localStorage.setItem(RE_USERNAME, data)
        },
        [Mutations.RESET_USERNAME](state) {
            state.username = undefined
            queryClient.setQueryData(['auth', 'user'], {})
            localStorage.removeItem(RE_USERNAME)
            console.debug('Clearing IndexedDB')
            clear()
        },
    },
    actions: {
        // NOTE: this is a horrid hack to work around the lack of inject/provide in vue2
        // This allows us access to curUser everywhere, even when no `this` context is available
        // TODO: remove this
        [Actions.CHECK_AUTHENTICATION](context) {
            // const axios = await import('@/shared/plugins/axios')
            // console.debug('axios', axios)
            const config = inject('config')

            return axios
                .get(utils.urlJoin(config.AUTH_API_URL, 'user'))
                .then((response) => {
                    context.commit(Mutations.SET_AUTHENTICATED, !!response.data?.authenticated)
                    if (response.data?.username) {
                        // NOTE: we set authUser cache here, so it is immediately available (to router esp)
                        queryClient.setQueryData(['auth', 'user'], response.data)
                        context.commit(Mutations.SET_USERNAME, response.data.username)
                    }
                    // set localstorage for re-username
                    // store re-username in the state
                })
                .catch(() => {
                    context.commit(Mutations.SET_AUTHENTICATED, false)
                    context.commit(Mutations.RESET_USERNAME)
                })
        },
        [Actions.CHECK_OFFLINE_AUTHENTICATION](context) {
            if (localStorage.getItem(RE_USERNAME) !== 'undefined') {
                context.commit(Mutations.SET_AUTHENTICATED, true)
                context.commit(Mutations.SET_USERNAME, localStorage.getItem(RE_USERNAME))
            } else {
                // Fallback on online auth:
                context.dispatch(Actions.CHECK_AUTHENTICATION)
            }
        },
        [Actions.STORE_AUTH_USER](context, curUser) {
            context.state.curUser = curUser
        },
        [Actions.LOGOUT](context) {
            context.commit(Mutations.RESET_USERNAME)
            context.commit(Mutations.SET_AUTHENTICATED, false)
        },
    },
}
export default auth
