<template>
    <div></div>
</template>

<script>
import utils from '@/shared/plugins/utils'
import axios from '@/shared/plugins/axios'

export default {
    name: 'CronofyCallback',
    inject: ['curUser'],
    mounted() {
        // Returning from a Cronofy add account flow
        if (this.$route.query.state) {
            const code = this.$route.query.code
            const state = JSON.parse(this.$route.query.state)
            const { backend_state, name, query } = state
            const callbackPath = this.$router.resolve({ name: 'cronofy-callback' })
            const callbackUrl = new URL(callbackPath.href, window.location.origin)

            axios
                .post(
                    utils.urlJoin(this.$config.VALUATION_API_URL, [
                        'availability',
                        'user',
                        this.curUser.userId,
                    ]),
                    {
                        code,
                        state: backend_state,
                        redirect_uri: callbackUrl.href,
                    }
                )
                .finally(() => {
                    this.$router.push({
                        name,
                        query,
                    })
                })
        } else {
            // TODO: Show error message
            this.$router.push({
                name: 'valuation-settings',
            })
        }
    },
}
</script>

<style scoped lang="scss">
section {
    display: grid;

    .warning {
        display: flex;
        padding: 1rem;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        flex: 1 0 0;
        border-radius: 0.5rem;
        background: color($warning, $alpha: -80%);
        margin: auto;
        max-width: 40rem;
        &__icon {
            display: flex;
            width: 2.5rem;
            height: 2.5rem;
            padding: 0.52081rem;
            justify-content: center;
            align-items: center;
            gap: 0.52081rem;
            border-radius: 4.16669rem;
            background: color($warning, $alpha: -80%);
            img {
                flex-shrink: 0;
                fill: $warning;
            }
        }
        &__text {
            color: var(--q-dark);
            text-align: center;
            font-size: 0.75rem;
            font-style: normal;
            font-weight: 500;
            line-height: 1.125rem; /* 150% */
        }
    }
}
</style>
